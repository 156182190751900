import { useState, FC } from "react";
import style from "./actionMenu.module.css";
import commandGoImg from "../../img/moveNew.png";
import fire from "../../img/fireNew1.png";
import fire_move from "../../img/fireMove.png";
import def from "../../img/defense.png";
import attack from "../../img/attack.png";
import hardF from "../../img/gunHard.png";
import moveWar from "../../img/moveWar.png";
import gun_zacep from "../../img/gunSUp.png";
import gun1 from "../../img/pushka1.png";
import gun2 from "../../img/pushka2.png";
import vihod1 from "../../img/vihod.png";
import vihod2 from "../../img/vihod2.png";
import zasada from "../../img/zazsada.png";
import sumGiveSklad from "../../img/supGiveSklad.png";
import giveSup from "../../img/supGruz.png";
import sectorSmena from "../../img/sectorSmena.png";
import saperOkop from "../../img/saper6.png";
import saperProvolovkaDelete from "../../img/saper5.png";
import saperEjiDelete from "../../img/saper4.png";
import saperRazMin from "../../img/saper3.png";
import saperMin from "../../img/saper2.png";
import saperMostPonton from "../../img/saper1.png";
import { ITank } from "../../types/types";




interface IActionMenu {
  visible: string;
  setAction?: any;
  setActiveTank: any;
  tank:ITank;
}
export const ActionMenu: FC<IActionMenu> = ({
  visible = "none",
  setAction,
  setActiveTank,
  tank,
}) => {
  const [activeMenu, setActiveMenu] = useState(false);
  // setAction('')
  
  return (
    <div style={{ display: visible }} className={style.menu}>
      <div onClick={() => setAction("move")} style={ tank.name != "dot" ? {display:"block"} : {display:"none"} }  className={style.menu_item}>
        <img src={commandGoImg} alt="go" />
      </div>
      <div onClick={() => setAction("attack")} style={ (tank.type === "humans" && tank.name != "dot" ) || tank.type === "artillery" || tank.name === "zis5"  ? {display:"block"} : {display:"none"} } className={  style.menu_item  }>
        <img src= { moveWar} alt="att" />
      </div>
      <div onClick={() => setAction("fire")} className={style.menu_item}>
        <img src={fire} alt="fire" />
      </div>
      <div onClick={() => setAction("defend")} style={ tank.name != "dot" ? {display:"block"} : {display:"none"} } className={style.menu_item}>
        <img src={def} alt="deff" />
      </div>
      <div onClick={() => setAction("fire_hard")} className={style.menu_item}>
        <img src={hardF} alt="hard_fire" />
      </div>
      <div onClick={() => setAction("attack")} style={ ( (tank.type === "humans" && tank.name != "dot" ) && tank.name != "mg34" && tank.name != "maxim"  )|| tank.type === "middle" || tank.type === "light"  ? {display:"block"} : {display:"none"} } className={style.menu_item}>
        <img src={attack} alt="att" />
      </div>
      <div onClick={() => setAction("attack")}  className={ false ? style.menu_item : style.menu_item5 }>
        <img src= { fire_move } alt="att" />
      </div>
      <div onClick={() => setAction("attack")} style={ tank.name === "stormtrooper" ? {display:"block"} : {display:"none"} } className={ style.menu_item }>
        <img src= { saperMin } alt="att" />
      </div>
      <div onClick={() => setAction("attack")} style={ tank.name === "stormtrooper" ? {display:"block"} : {display:"none"} } className={ style.menu_item }>
        <img src= { saperMostPonton } alt="att" />
      </div>
      <div onClick={() => setAction("attack")} style={ tank.name === "stormtrooper" ? {display:"block"} : {display:"none"} } className={ style.menu_item }>
        <img src= { saperEjiDelete } alt="att" />
      </div>
      <div onClick={() => setAction("attack")} style={ tank.name === "stormtrooper" ? {display:"block"} : {display:"none"} } className={ style.menu_item }>
        <img src= { saperOkop } alt="att" />
      </div>
      <div onClick={() => setAction("attack")} style={ tank.name === "stormtrooper" ? {display:"block"} : {display:"none"} } className={ style.menu_item }>
        <img src= { saperProvolovkaDelete } alt="att" />
      </div>
      <div onClick={() => setAction("attack")} style={ tank.name === "stormtrooper" ? {display:"block"} : {display:"none"} } className={ style.menu_item }>
        <img src= { saperRazMin } alt="att" />
      </div>
      <div onClick={() => setAction("attack")} style={ tank.type === "artillery" || tank.name ==="zis5" ? {display:"block"} : {display:"none"} }  className={ style.menu_item }>
        <img src= { gun_zacep } alt="att" />
      </div>
      <div onClick={() => setAction("attack")}  className={ style.menu_item }>
        <img src= { giveSup } alt="att" />
      </div>
      <div onClick={() => setAction("attack")} style={ tank.type === "artillery" ? {display:"block"} : {display:"none"} } className={ style.menu_item }>
        <img src= { gun1 } alt="att" />
      </div>
      <div onClick={() => setAction("attack")} style={ tank.type === "artillery" ? {display:"block"} : {display:"none"} } className={ style.menu_item }>
        <img src= { gun2 } alt="att" />
      </div>
      <div onClick={() => setAction("attack")} style={ (tank.type === "humans" && tank.name != "dot" ) ? {display:"block"} : {display:"none"} } className={ style.menu_item }>
        <img src= { vihod1 } alt="att" />
      </div>
      <div onClick={() => setAction("attack")} style={ (tank.type === "humans" && tank.name != "dot" ) ? {display:"block"} : {display:"none"} } className={ style.menu_item }>
        <img src= { vihod2 } alt="att" />
      </div>
      <div onClick={() => setAction("attack")} style={ (tank.type === "humans" && tank.name != "dot" ) || tank.type === "artillery"  ? {display:"block"} : {display:"none"} }  className={ style.menu_item }>
        <img src= { zasada } alt="att" />
      </div>
      <div onClick={() => setAction("attack")} style={ tank.type === "artillery" || tank.name === "maxim" || tank.name === "mg34"  ? {display:"block"} : {display:"none"} }  className={ style.menu_item }>
        <img src= { sectorSmena } alt="att" />
      </div>
      <div onClick={() => setAction("attack")} style={  tank.name ==="zis5" ? {display:"block"} : {display:"none"} }  className={ style.menu_item }>
        <img src= { sumGiveSklad } alt="att" />
      </div>
      {/* <div onClick={() => setActiveTank(0)} className={style.menu_item}>
        Выйти
      </div> */}
    </div>
  );
};
