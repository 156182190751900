import { ICanGo } from "../gameComponents/Field/Field"
import { ICell, ICoordinatesTank, IDataField } from "../types/types"

interface IChangeCoordinates {
    cell: ICell
    col: IDataField
    colField:number,
    rowField:number,
    maxHeightField:number
    /*kef: Idatafield*/
}



interface IChangeCoordinates1 {
    cell1: ICoordinatesTank
    col: IDataField

    /*kef: Idatafield*/
}

export const respawnCoordinates = (row: number, col: number,colField:number, rowField:number,maxHeightField:number,id:number) => {
    const type = col % 2 ? 1 : 2
    let line = type === 1 ? 1 + (12.5 * (row - 2)) : 6.5 + (13 * (row - 1))
    let column = 1 + (8* (col - 1))
    const newCoordinates = { top: line, left: column, col: col, row: row, type: type,id:id }
   
    return newCoordinates
}

export const changeCoordinates = ({ cell, col,colField,rowField,maxHeightField }: IChangeCoordinates) => {
    
    var row = col.type === 1 ?((1/maxHeightField)*100)+ (100/rowField) * (cell.row-1)  :((55/maxHeightField)*100)+ (100/rowField) * (cell.row-1)  ;
    var column = col.type === 1 ? (101/colField) * (cell.col-1) : (102/colField) * (cell.col-1)  ;
    console.log(rowField,colField,maxHeightField)
    const newCoordinates = { top: row, left: column, col: cell.col, row: cell.row, type: col.type}
    
    return newCoordinates
    
}


    
