import { useState } from "react";
import { ICell, ICoordinatesTank, IDataField, IDataFieldAll, ITank } from "../types/types";
import { dataField, dataFieldAll } from "../mockData/mockData";
import { Location, useLocation } from "react-router-dom";


interface cell {
      row: number, col: number, type: number, kef?: number, id?:number,
  }


 
  interface IReturnedArray {
    radius: number
    cells: cell[]
  }
let gg100:number[]
let gg1001:cell[]
let hh:cell[]
let hh1:cell[]
let hh2:cell[]
let hh3:cell[]
let hh4:cell[]
let array:ICell[]
let gg10: {row:number,col:number,type:number} | undefined
export const VectorVizual =(row: number, col: number, type: number, numMoves: number = 1,moveTank:number,cell12:ICell , rowDataField:number,colDataField:number,dataField1:{name: string;
  sections: {
      id: number;
      col: number;
      row: number;
      kef: number;
      img: string;
      baseDefend: {
          tanks: number;
          humans: number;
      };
      visible: boolean;
  }[];
  kef: number;
  type: number;}[], kef?:  number,id?:number, ) => {
  
  let sum = 0;
  let sum1 = 0;
  let sum2 = 0;
  let sum3 = 0;
      let h  = dataField1[col - 1].sections[row -1].id
      let h12 =dataField1[col - 1].sections[row -1].id
      const j = id
      const h1 = col
      console.log(h12)
     const canGoSellMore = (row: number, col: number, type: number, move12:number  , kef?: number, id?: number,) => {
       console.log(row,col)
       let array: cell[]
      let array2: cell[]
     
    
       if (type === 1) {
         
         array =
   
           [
             { row: row + 1, col: col     , type: 1 , kef: row+1 <= rowDataField && row+1 >= 1 && col   > 0  && col <= colDataField ? dataField1[col-1].sections[row].kef : undefined,id: row+1 <= rowDataField && row+1 >= 1 && col   > 0  && col <= colDataField ? dataField1[col-1].sections[row].id :6 },
             { row: row , col: col +1, type: 2, kef: row  <= rowDataField && row >= 1 && col +1  > 0 && col + 1 <= colDataField   ? dataField1[col].sections[row-1].kef : undefined , id: row  <= rowDataField && row >= 1 && col +1  > 0 && col + 1 <= colDataField   ? dataField1[col].sections[row-1].id : 5 },
             {row: row - 1, col: col + 1, type: 2 , kef: row-1 <= rowDataField && row-1 >= 1 && col + 1 >0   && col+1 <= colDataField? dataField1[col].sections[row-2].kef : undefined , id: row-1 <= rowDataField && row-1 >= 1 && col + 1 >0   && col+1 <= colDataField? dataField1[col].sections[row-2].id : 4 },
             { row: row - 1, col: col , type: 1 ,kef: row-1 <= rowDataField && row-1 >= 1 && col > 0   && col <= colDataField? dataField1[col-1].sections[row-2].kef : undefined , id: row-1 <= rowDataField && row-1 >= 1 && col  >0   && col <= colDataField? dataField1[col-1].sections[row-2].id : 3  },
             { row: row  , col: col - 1, type: 2, kef: row <= rowDataField && row >= 1 && col-1 >0   && col-1 <=colDataField ?  dataField1[col-2].sections[row-1].kef: undefined , id: row <= rowDataField && row >= 1 && col - 1 >0   && col-1 <= colDataField? dataField1[col-2].sections[row-1].id : 10 },
             { row: row - 1, col: col - 1, type: 2, kef: row-1 <= rowDataField && row-1 >= 1 && col-1 > 0  && col-1 <=colDataField ? dataField1[col-2].sections[row-2].kef : undefined , id:row-1 <= rowDataField && row-1 >= 1 && col-1 > 0  && col-1 <=colDataField ? dataField1[col-2].sections[row-2].id : 1 },
           ]
           
          
           
          
     
       
         return array.filter((cell)=> cell.kef! <= moveTank )
       } else {
       
         array =
           [
             { row: row+ 1 , col: col     , type: 2, kef: row+1 <= rowDataField && row+1 >= 1 && col   > 0  && col <= colDataField ? dataField1[col-1].sections[row].kef  : undefined , id: row+1 <= rowDataField && row+1 >= 1 && col   > 0  && col <= colDataField ? dataField1[col-1].sections[row].id : 6},  
             { row: row , col: col+1  , type: 1, kef: row  <= rowDataField && row >= 1 && col +1  > 0 && col + 1 <= colDataField   ? dataField1[col].sections[row-1].kef : undefined , id:row  <= rowDataField && row >= 1 && col +1  > 0 && col + 1 <= colDataField   ? dataField1[col].sections[row-1].id : 5},
             { row: row -1 , col: col  , type: 2, kef: row-1 <= rowDataField && row-1 >= 1 && col   >0   && col <= colDataField? dataField1[col-1].sections[row-2].kef : undefined,  id:  row-1 <= rowDataField && row-1 >= 1 && col   >0   && col <= colDataField? dataField1[col-1].sections[row-2].id : 4},
             { row: row + 1, col: col - 1, type: 1,  kef: row+1 <= rowDataField && row+1 >= 1 && col - 1  > 0   && col - 1  <= colDataField? dataField1[col-2].sections[row].kef : undefined, id:row+1 <= rowDataField && row+1 >= 1 && col - 1  > 0   && col - 1  <= colDataField? dataField1[col-2].sections[row].id : 3  },
             { row: row, col: col - 1, type: 1, kef: row <= rowDataField && row >= 1 && col-1 >0   && col-1 <=colDataField ?  dataField1[col-2].sections[row-1].kef: undefined, id:row <= rowDataField && row >= 1 && col-1 >0   && col-1 <=colDataField ?  dataField1[col-2].sections[row-1].id: 2 },
             { row: row + 1, col: col + 1, type: 1 , kef: row+1 <= rowDataField && row+1 >= 1 && col+1 > 0  && col+1 <=colDataField ? dataField1[col].sections[row].kef : undefined , id:row+1 <= rowDataField && row+1 >= 1 && col+1 > 0  && col+1 <=colDataField ? dataField1[col].sections[row].id: 1},
           ]
           
         // setActiveZoneGo(array)
       
         return   array.filter((cell)=> cell.kef! <= moveTank)
         
       } }
   
   
       const canGoSellMore1 = (row: number, col: number, type: number, move12:number,arr:cell[], kef?: number,id?: number ) => {
        
         let array: cell[]
         let array2:cell[]
         
        
       kef! = move12 - kef!
       console.log(kef!)
         if (type === 1) {
          
           array =
     
             [
               { row: row + 1, col: col     , type: 1 , kef: row+1 <= rowDataField && row+1 >= 1 && col   > 0  && col <= colDataField ? kef - dataField1[col-1].sections[row].kef    : undefined, id: row+1 <= rowDataField && row+1 >= 1 && col   > 0  && col <= colDataField ? dataField1[col-1].sections[row].id : undefined   },
               { row: row, col: col +1, type: 2, kef: row  <= rowDataField && row >= 1 && col +1  > 0 && col + 1 <= colDataField   ?kef - dataField1[col].sections[row-1].kef  : undefined , id: row  <= rowDataField && row >= 1 && col +1  > 0 && col + 1 <= colDataField   ? dataField1[col].sections[row-1].id : undefined },
               { row: row - 1, col: col + 1, type: 2 , kef: row-1 <= rowDataField && row-1 >= 1 && col + 1 >0   && col+1 <= colDataField? kef - dataField1[col].sections[row-2].kef   : undefined, id: row-1 <= rowDataField && row-1 >= 1 && col + 1 >0   && col+1 <= colDataField? dataField1[col].sections[row-2].id : undefined  },
               { row: row - 1, col: col , type: 1 ,kef: row-1 <= rowDataField && row-1 >= 1 && col > 0   && col <= colDataField? kef -  dataField1[col-1].sections[row-2].kef   : undefined , id: row-1 <= rowDataField && row-1 >= 1 && col + 1 >0   && col+1 <= colDataField? dataField1[col-1].sections[row-2].id : undefined  },
               { row: row  , col: col - 1, type: 2, kef: row <= rowDataField && row >= 1 && col-1 >0   && col-1 <=colDataField ? kef - dataField1[col-2].sections[row-1].kef   : undefined , id: row-1 <= rowDataField && row >= 1 && col - 1 >0   && col-1 <= colDataField? dataField1[col-2].sections[row-1].id : undefined },
               { row: row - 1, col: col - 1, type: 2, kef: row-1 <= rowDataField && row-1 >= 1 && col-1 > 0  && col-1 <=colDataField ?kef - dataField1[col-2].sections[row-2].kef   : undefined ,  id:row-1 <= rowDataField && row-1 >= 1 && col-1 > 0  && col-1 <=colDataField ? dataField1[col-2].sections[row-2].id : undefined },
             ]
             
               
          
              
           return  array.filter((cell)=> cell.kef! >=0   ) 
         } else {
           array =
             [
               { row: row+ 1, col: col     , type: 2, kef: row+1 <= rowDataField && row+1 >= 1 && col   > 0  && col <= colDataField ?kef -dataField1[col-1].sections[row].kef   : undefined , id: row+1 <= rowDataField && row+1 >= 1 && col   > 0  && col <= colDataField ? dataField1[col-1].sections[row].id : undefined },  
               { row:  row  , col: col+1  , type: 1, kef: row  <= rowDataField && row >= 1 && col +1  > 0 && col + 1 <= colDataField   ? kef - dataField1[col].sections[row-1].kef  : undefined , id:row  <= rowDataField && row >= 1 && col +1  > 0 && col + 1 <= colDataField   ? dataField1[col].sections[row-1].id : undefined},
               { row:  row -1 , col: col  , type: 2, kef: row-1 <= rowDataField && row-1 >= 1 && col   >0   && col <= colDataField? kef -  dataField1[col-1].sections[row-2].kef  : undefined ,  id:  row-1 <= rowDataField && row-1 >= 1 && col   >0   && col <= colDataField? dataField1[col-1].sections[row-2].id : undefined},
               { row:  row + 1, col: col - 1, type: 1,  kef: row+1 <= rowDataField && row+1 >= 1 && col - 1  > 0   && col - 1  <= colDataField? kef - dataField1[col-2].sections[row].kef  : undefined , id:row+1 <= rowDataField && row+1 >= 1 && col - 1  > 0   && col - 1  <= colDataField? dataField1[col-2].sections[row].id : undefined    },
               { row: row, col: col - 1, type: 1, kef: row <= rowDataField && row >= 1 && col-1 >0   && col-1 <=colDataField ? kef -  dataField1[col-2].sections[row-1].kef : undefined,id:row <= rowDataField && row >= 1 && col-1 >0   && col-1 <=colDataField ?  dataField1[col-2].sections[row-1].id: undefined  },
               { row:  row + 1, col: col + 1, type: 1 , kef: row+1 <= rowDataField && row+1 >= 1 && col+1 > 0  && col+1 <=colDataField ?kef - dataField1[col].sections[row].kef   : undefined, id:row+1 <= rowDataField && row+1 >= 1 && col+1 > 0  && col+1 <=colDataField ? dataField1[col].sections[row].id: undefined },
             ]
     
           // setActiveZoneGo(array)
          
           return   array.filter((cell)=> cell.kef! >=0  ) 
           
         } }
   
   
         const canGoSellMore2 = (row: number, col: number, type: number, move12:number, cell1:cell  ,kef?: number, id?: number ) => {
      
           let array: cell[]
           
           
           
          
           if (type === 1) {
              
             array =
       
               [
                 { row: row + 1, col: col     , type: 1 , kef: row+1 <= rowDataField && row+1 >= 1 && col   > 0  && col <= colDataField ?kef! -  dataField1[col-1].sections[row].kef   : undefined , id: row+1 <= rowDataField && row+1 >= 1 && col   > 0  && col <= colDataField ? dataField1[col-1].sections[row].id : undefined  },
                 { row: row , col: col +1, type: 2, kef: row  <= rowDataField && row >= 1 && col +1  > 0 && col + 1 <= colDataField   ?kef! - dataField1[col].sections[row-1].kef    : undefined , id: row  <= rowDataField && row >= 1 && col +1  > 0 && col + 1 <= colDataField   ? dataField1[col].sections[row-1].id : undefined},
                 { row: row - 1, col: col + 1, type: 2 , kef: row-1 <= rowDataField && row-1 >= 1 && col + 1 >0   && col+1 <= colDataField? kef! - dataField1[col].sections[row-2].kef     : undefined , id: row-1 <= rowDataField && row-1 >= 1 && col + 1 >0   && col+1 <= colDataField? dataField1[col].sections[row-2].id : undefined },
                 { row: row - 1, col: col , type: 1 ,kef: row-1 <= rowDataField && row-1 >= 1 && col > 0   && col <= colDataField?kef! - dataField1[col-1].sections[row-2].kef       : undefined , id: row-1 <= rowDataField && row-1 >= 1 && col + 1 >0   && col+1 <= colDataField? dataField1[col-1].sections[row-2].id : undefined },
                 { row: row  , col: col - 1, type: 2, kef: row <= rowDataField && row >= 1 && col-1 >0   && col-1 <=colDataField ?kef! -dataField1[col-2].sections[row-1].kef  : undefined , id: row-1 <= rowDataField && row-1 >= 1 && col - 1 >0   && col-1 <= colDataField? dataField1[col-2].sections[row-1].id : undefined},
                 {row: row - 1, col: col - 1, type: 2, kef: row-1 <= rowDataField && row-1 >= 1 && col-1 > 0  && col-1 <=colDataField ?kef! - dataField1[col-2].sections[row-2].kef   : undefined ,  id:row-1 <= rowDataField && row-1 >= 1 && col-1 > 0  && col-1 <=colDataField ? dataField1[col-2].sections[row-2].id : undefined },
               ]
       
             return array.filter((cell2)=> cell2.kef! >= 0 )
           } else {
             array =
               [
                 { row: row+ 1 , col: col     , type: 2, kef: row+1 <= rowDataField && row+1 >= 1 && col   > 0  && col <= colDataField ? kef! - dataField1[col-1].sections[row].kef  : undefined  , id: row+1 <= rowDataField && row+1 >= 1 && col   > 0  && col <= colDataField ? dataField1[col-1].sections[row].id : undefined},  
                 { row: row , col: col+1  , type: 1, kef: row  <= rowDataField && row >= 1 && col +1  > 0 && col + 1 <= colDataField   ? kef! -  dataField1[col].sections[row-1].kef   : undefined , id:row  <= rowDataField && row >= 1 && col +1  > 0 && col + 1 <= colDataField   ? dataField1[col].sections[row-1].id : undefined},
                 { row: row -1 , col: col  , type: 2, kef: row-1 <= rowDataField && row-1 >= 1 && col   >0   && col <= colDataField? kef! -  dataField1[col-1].sections[row-2].kef  : undefined ,  id:  row-1 <= rowDataField && row-1 >= 1 && col   >0   && col <= colDataField? dataField1[col-1].sections[row-2].id : undefined},
                 { row: row + 1, col: col - 1, type: 1,  kef: row+1 <= rowDataField && row+1 >= 1 && col - 1  > 0   && col - 1  <= colDataField?kef! -  dataField1[col-2].sections[row].kef   : undefined  , id:row+1 <= rowDataField && row+1 >= 1 && col - 1  > 0   && col - 1  <= colDataField? dataField1[col-2].sections[row].id : undefined    },
                 { row: row, col: col - 1, type: 1, kef: row <= rowDataField && row >= 1 && col-1 >0   && col-1 <=colDataField ? kef! -   dataField1[col-2].sections[row-1].kef  : undefined ,id:row <= rowDataField && row >= 1 && col-1 >0   && col-1 <=colDataField ?  dataField1[col-2].sections[row-1].id: undefined },
                 { row: row + 1, col: col + 1, type: 1 , kef: row+1 <= rowDataField && row+1 >= 1 && col+1 > 0  && col+1 <=colDataField ? kef! - dataField1[col].sections[row].kef   : undefined , id:row+1 <= rowDataField && row+1 >= 1 && col+1 > 0  && col+1 <=colDataField ? dataField1[col].sections[row].id: undefined },
               ]
       
             // setActiveZoneGo(array)
       
             return  array.filter((cell2)=> cell2.kef! >= 0 )
             
           } }
           
       let cirOne : IReturnedArray[] = [{ radius: 0, cells: [{id:0, row: 0, col: 0, type: 1, kef:kef }] }]
       
       
       //let Return : IReturnedArray[][] = [{  cells: [{ row: 0, col: 0, type: 1, kef:kef }] }]
       let returnedCoordinatesArray: IReturnedArray[] = [{ radius: 0, cells: [{id:0, row: 0, col: 0, type: 1, kef:kef }] }]
       
     
       for (let i = 0; i < numMoves; i++) {
          
       
      
         if (i === 0) {
         
          let  hhh = []
         
         let b = cirOne[i].cells = canGoSellMore(row,col,type,moveTank,kef,id)
       
         let gg3 = -1;
          gg100 = [];
         
         let cells_find = {cell:[{row:3,col:3,type:3}],cells:[[{row:3,col:3,type:3}]]}
         let cells_find1 = {cell:[{row:3,col:3,type:3}],cells:[[{row:3,col:3,type:3}]]}
         let cells_find2 = {cell:[{row:3,col:3,type:3}],cells:[[{row:3,col:3,type:3}]]}
         let cells_find3 = {cell:[{row:3,col:3,type:3}],cells:[[{row:3,col:3,type:3}]]}
         let gg5;
         hh1 = []
         hh2 = []
         hh3 = []
         hh4 = []
        
          cells_find.cell = b
          cells_find.cells   =  b.map((cell1,index,b) => { return  canGoSellMore1(cell1.row,cell1.col,cell1.type,moveTank,b,cell1.kef!,cell1.id!) } )
          let gf = b.map((cell1,index,b) => canGoSellMore1(cell1.row,cell1.col,cell1.type,moveTank,b,cell1.kef!,cell1.id!) )
          cells_find1.cell = cells_find.cells.flat()
          cells_find1.cells = gf.map((cell=> cell.map((cell)=>canGoSellMore2(cell.row,cell.col,cell.type,moveTank,cell,cell.kef!,cell.id!) ))).flat(1)
          let gfg = gf.map((cell=> cell.map((cell)=>canGoSellMore2(cell.row,cell.col,cell.type,moveTank,cell,cell.kef!,cell.id!) )))
          cells_find2.cell = cells_find1.cells.flat()
          cells_find2.cells = gfg.map((cell)=> cell.map((cell) => cell.map((cell)=> canGoSellMore2(cell.row,cell.col,cell.type,moveTank,cell,cell.kef!,cell.id!)) ) ).flat(2)
          let gfg1 = gfg.map((cell)=> cell.map((cell) => cell.map((cell)=> canGoSellMore2(cell.row,cell.col,cell.type,moveTank,cell,cell.kef!,cell.id!)) ) )
          cells_find3.cell = cells_find2.cells.flat()
          cells_find3.cells = gfg1.map((cell)=> cell.map((cell)=> cell.map((cell)=> cell.map((cell)=> canGoSellMore2(cell.row,cell.col,cell.type,moveTank,cell,cell.kef!,cell.id!))))).flat(3)
          let gg =   cells_find.cells.find((cell,index1,arr) => cell.find((cell500,index,arr) => { return  cell500.col === cell12.col && cell500.row === cell12.row?  gg3 =index1  : '' } ))
          //let gg34 =   cells_find.cells.find((cell,index1,arr) => cell.find((cell500,index,arr) => cell500.col === cell12.col && cell500.row ===  cell12.row ))
          let g20;
            let g = b.find((cell) => cell.col === cell12.col && cell.row=== cell12.row)
           
                 console.log(gg,cell12,gg3)
        if (!g) {
         
           if (gg3 !== -1) {
            
             g20 = cells_find.cell.find((cell,index) => index === gg3)
              console.log(g20)
                hh1.push(g20!)
                 hh1.map((cell) => sum+=cell.kef!)
                console.log(4444)
           }else {
            gg =  cells_find1.cells.find((cell,index1,arr) => cell.find((cell,index,arr) => cell.col === cell12.col && cell.row === cell12.row?  gg3 =index1: '' ))
              if (gg3 !== -1) {
                console.log(1111)
                g20 = cells_find1.cells.filter((cell,index) => cell.find((cell) => cell.row === cell12.row && cell.col === cell12.col ? gg100.push(index) : '' ))
                gg = cells_find1.cell.filter((cell,index) => index === gg100.find((num) => num === index))
                // eslint-disable-next-line 
                gg[0] === undefined  ?  ""   :hh1.push(gg[0])
                // eslint-disable-next-line 
                 gg[1] === undefined  ?  ''   : hh2.push(gg[1])
                console.log(g20,gg,gg100)
                console.log(hh1,hh2)
                gg100 = []
                g20 = cells_find.cells.filter((cell,index) => cell.find((cell) => cell === gg?.find((cell13) => cell13 === cell)  ? gg100.push(index) : '' ))
                gg = cells_find.cell.filter((cell,index) => index === gg100.find((num) => num === index))
                console.log(g20,gg,gg100)
// eslint-disable-next-line 
                gg[0] === undefined  ?  ''   :hh1.push(gg[0])
               // gg[1] === undefined  ?  ''   :hh1.push(gg[1])

// eslint-disable-next-line 
                gg[1] === undefined  ?  ''   : hh2.push(gg[1])
            //    gg[1] === undefined  ?  ''   : hh2.push(gg[1])
                console.log(hh1,hh2)
               hh1.map((cell) => sum+=cell.kef!)
               hh2.map((cell) => sum1+=cell.kef!)
              }else {
                
                console.log(1111)
                gg =  cells_find2.cells.find((cell,index1,arr) => cell.find((cell,index,arr) => cell.col === cell12.col && cell.row === cell12.row?  gg3 =index1: '' ))
                   if (gg3 !== -1) {
                     console.log(1111)
                     console.log(1111)
                     g20 = cells_find2.cells.filter((cell,index) => cell.find((cell) => cell.row === cell12.row && cell.col === cell12.col ? gg100.push(index) : '' ))
                  
                     gg = cells_find2.cell.filter((cell,index) => index === gg100.find((num) => num === index))
                  // eslint-disable-next-line 
                     gg[0] === undefined  ?  ''   :hh1.push(gg[0])
                    // eslint-disable-next-line 
                     gg[1] === undefined  ?  ''   : hh2.push(gg[1])
                     console.log(g20,gg,gg100)
                     console.log(hh1,hh2)
                     gg100 = []
                     g20 = cells_find1.cells.filter((cell,index) => cell.find((cell) => cell === gg?.find((cell13) => cell13 === cell)  ? gg100.push(index) : '' ))
                     gg = cells_find1.cell.filter((cell,index) => index === gg100.find((num) => num === index))
                     console.log(g20,gg,gg100)
     // eslint-disable-next-line 
                     gg[0] === undefined  ?  ''   :hh1.push(gg[0])
                    // gg[1] === undefined  ?  ''   :hh1.push(gg[1])
     
     // eslint-disable-next-line 
                     gg[1] === undefined  ?  ''   : hh2.push(gg[1])
                 //    gg[1] === undefined  ?  ''   : hh2.push(gg[1])
                 gg100 = []
                 g20 = cells_find.cells.filter((cell,index) => cell.find((cell) => cell === gg?.find((cell13) => cell13 === cell)  ? gg100.push(index) : '' ))
                 gg = cells_find.cell.filter((cell,index) => index === gg100.find((num) => num === index))
                 console.log(g20,gg,gg100)
 // eslint-disable-next-line 
                 gg[0] === undefined  ?  ''   :hh1.push(gg[0])
                // gg[1] === undefined  ?  ''   :hh1.push(gg[1])
 
 // eslint-disable-next-line 
                 gg[1] === undefined  ?  ''   : hh2.push(gg[1])
             //    gg[1] === undefined  ?  ''   : hh2.push(gg[1])
                     console.log(hh1,hh2)
                    hh1.map((cell) => sum+=cell.kef!)
                    hh2.map((cell) => sum1+=cell.kef!)





                   }else {
                    console.log(1111)
                    gg =  cells_find3.cells.find((cell,index1,arr) => cell.find((cell,index,arr) => cell.col === cell12.col && cell.row === cell12.row?  gg3 =index1: '' ))
                       if (gg3 !== -1) {

                        console.log(1111)
                        console.log(1111)
                        g20 = cells_find3.cells.filter((cell,index) => cell.find((cell) => cell.row === cell12.row && cell.col === cell12.col ? gg100.push(index) : '' ))
                        gg = cells_find3.cell.filter((cell,index) => index === gg100.find((num) => num === index))
                        console.log(g20,gg)
                     // eslint-disable-next-line 
                        gg[0] === undefined  ?  ''   :hh1.push(gg[0])
                      // eslint-disable-next-line 
                        gg[1] === undefined  ?  ''   : hh2.push(gg[1])
                      // eslint-disable-next-line 
                        gg[2] === undefined  ?  ''   : hh3.push(gg[2])
                      // eslint-disable-next-line 
                        gg[3] === undefined  ?  ''   : hh4.push(gg[3])
                        console.log(g20,gg,gg100)
                        console.log(hh1,hh2)
                        gg100 = []
                        g20 = cells_find2.cells.filter((cell,index) => cell.find((cell) => cell === gg?.find((cell13) => cell13 === cell)  ? gg100.push(index) : '' ))
                        gg = cells_find2.cell.filter((cell,index) => index === gg100.find((num) => num === index))
                        console.log(g20,gg,gg100)
        // eslint-disable-next-line 
                        gg[0] === undefined  ?  ''   :hh1.push(gg[0])
                     // eslint-disable-next-line 
                        gg[1] === undefined  ?  ''   : hh2.push(gg[1])
                     // eslint-disable-next-line 
                        gg[2] === undefined  ?  ''   : hh3.push(gg[2])
                    // eslint-disable-next-line 
                        gg[3] === undefined  ?  ''   : hh4.push(gg[3])
                    gg100 = []
                    g20 = cells_find1.cells.filter((cell,index) => cell.find((cell) => cell === gg?.find((cell13) => cell13 === cell)  ? gg100.push(index) : '' ))
                    gg = cells_find1.cell.filter((cell,index) => index === gg100.find((num) => num === index))
                    console.log(g20,gg,gg100)
    // eslint-disable-next-line 
                    gg[0] === undefined  ?  ''   :hh1.push(gg[0])
                  // eslint-disable-next-line 
                    gg[1] === undefined  ?  ''   : hh2.push(gg[1])
                 // eslint-disable-next-line 
                    gg[2] === undefined  ?  ''   : hh3.push(gg[2])
                  // eslint-disable-next-line 
                    gg[3] === undefined  ?  ''   : hh4.push(gg[3])
                gg100 = []
                g20 = cells_find.cells.filter((cell,index) => cell.find((cell) => cell === gg?.find((cell13) => cell13 === cell)  ? gg100.push(index) : '' ))
                gg = cells_find.cell.filter((cell,index) => index === gg100.find((num) => num === index))
                console.log(g20,gg,gg100)
// eslint-disable-next-line 
                        gg[0] === undefined  ?  ''   :hh1.push(gg[0])
                       // eslint-disable-next-line 
                        gg[1] === undefined  ?  ''   : hh2.push(gg[1])
                       // eslint-disable-next-line 
                        gg[2] === undefined  ?  ''   : hh3.push(gg[2])
                      // eslint-disable-next-line 
                        gg[3] === undefined  ?  ''   : hh4.push(gg[3])
                        console.log(hh1,hh2,hh3,hh4)
                        hh1.map((cell) => sum+=cell.kef!)
                        hh2.map((cell) => sum1+=cell.kef!)
                        hh3.map((cell) => sum2+=cell.kef!)
                        hh4.map((cell) => sum3+=cell.kef!)
                      
                       }else {
                        console.log(1111)



                       }


                   }



              }




           }
           
          } else {

          }


        
        


          let gfg3 = gfg1.map((cell)=> cell.map((cell)=> cell.map((cell)=> cell.map((cell)=> canGoSellMore2(cell.row,cell.col,cell.type,3,cell,cell.kef!,cell.id!)))))
      

         
          console.log(b)
   console.log(cells_find,cells_find1,cells_find2)
   console.log(gfg)
   console.log(gfg1)
   console.log(gfg3)  
   
       let h300 = gf.concat(gfg.flat(2)).concat(b).concat(gfg1.flat(2)).concat(gfg3.flat(5))
       
       let h500 = h300.flat(3)
       let h5= gf.flat(3)
      let h3000 =  h500.filter((cell) => cell.id  !=  h)
         console.log(h3000)
           returnedCoordinatesArray[i].radius = 1
           returnedCoordinatesArray[i].cells = h3000.flat(3) //[ {row: 3, col: 2, type: 2}]
          
           //cells =  canGoSellMore(row,col,type,kef)
           
           //canGoSellMore(row, col, type, kef)
         
         }
        
         if (i > 0) {
           let _newArray: IReturnedArray = { radius: 0, cells: [{id:id , row: row, col: col, type: type,kef:kef}] }
           let cells: cell[] = [{ id:0, row: 1, col: 1, type: 1, kef:3 }]
           _newArray.radius = i + 2
           returnedCoordinatesArray[i - 1].cells.map(coordinate => {
             const position = canGoSellMore(coordinate.row, coordinate.col, coordinate.type, coordinate.kef!,coordinate.id! )
             _newArray.cells.push(...position)
             
           })
           returnedCoordinatesArray.push(_newArray)
           
          
         }
       
          console.log(returnedCoordinatesArray)
       
       }
      
       return  sum >= sum1  ? sum >=sum2  ?  sum >= sum3 ? hh1 : hh4   : hh3   : hh2
       
     }