import { FC } from "react";
import style from "./victoryBar.module.css"
import { ITank } from "../../types/types";
import { mockTanks2, tanks } from "../../mockData/mockData";
import { useLocation, useNavigate } from "react-router-dom";




interface IVitoryBar {
 countMoves:number
 countMovesSov:number
 countMovesWer:number
 dataTank:ITank[]

}





export const VictoryBar: FC<IVitoryBar> = ({ countMoves, countMovesSov, countMovesWer,dataTank }) => {
let s = ""
let s11 = ""
const fdf = useNavigate()
let location1 = useLocation()

if (location1.state.cart === "Танковый бой") {
  const datatanks1 = dataTank.filter((tank) => tank.team === 1).every((tank) => tank.count === 0)
  const datatanks2 = dataTank.filter((tank) => tank.team === 2).every((tank) => tank.count === 0)
         if(countMovesSov >= 3   || datatanks1  ) {
          s = "Победа Вермахт"
          s11 = style.victoryBarWer
         } else if (countMovesWer >= 3 || datatanks2){
            s = "Победа РККА"
            s11 = style.victoryBarSov   
               
         } 



} else if (location1.state.cart === "Вторжение") {
  const datatanks2 = dataTank.filter((tank) => tank.team === 2).every((tank) => tank.count === 0)
  const datatanks3 = dataTank.filter((tank) => tank.coordinates.col === 19 && tank.coordinates.row === 3)
  if (countMoves === 3) {

    dataTank.filter((tank) => tank.name === "t34" ?  tank.coordinates = {row:1,col:10,type:1,left:47,top:7}  : tank)
    dataTank.filter((tank) => tank.name === "t34" ?  tank.count = 3  : tank)
  } 



  if( datatanks3.length === 2    ) {
    s = "Победа Вермахт"
    s11 = style.victoryBarWer
   } else if ( datatanks2){
      s = "Победа РККА"
      s11 = style.victoryBarSov   
         
   } 



} else if (location1.state.cart === "Окружение") {
  const datatanks2 = dataTank.find((tank) => tank.name === "zis5" && tank.count > 0 )
  const datatanks1 = dataTank.find((tank) => tank.name === "zis5" && tank.count <= 0 )

  if(   datatanks1 ) {
    s = "Победа Вермахт"
    s11 = style.victoryBarWer
   } else if ( countMoves >= 12 &&  datatanks2 ){
      s = "Победа РККА"
      s11 = style.victoryBarSov   
         
   } 



}




    return (
      <div className={style.sueta}>
      <div className={ s11 } >  {s} <button onClick={(() => fdf(`/sueta111/main`,{state:location1.state , replace:true}) )} style={{display: !s ? 'none' : 'flex'  }} className={style.end_play}>Покинуть игру</button></div>
      </div>
       
      
      
    );
  };