import { FC, useEffect, useState } from "react";
import img from './786.gif';
import style from "./movescount.module.css";
import { ITank } from "../../types/types";
import { Visible } from "../../logicGame/visible";
import { dataField, dataFieldAll, tanks } from "../../mockData/mockData";
import { useLocation, useParams } from "react-router-dom";

interface IMoveCount1 {
  setActiveTeam: any;
  activeTeam: number;
  setDataTanks:any
  dataTank:ITank[];
  count:number;
  setCount:any
  countWer:number
  countSov:number
  setCountWer:any
  setCountSov:any
  VisibleReady:any
  setCounter1:any
  onClickAdd:() => void
  msg:any[]
  fraction:any
  setAny: any

}
let s5: number[][]
let hhh: {id:number,tankNum:number[]}[]
let s9:number[]
export const MovesCount1: FC<IMoveCount1> = ({ setActiveTeam, activeTeam,setCounter1, setDataTanks, dataTank, count, setCount, onClickAdd, VisibleReady,countWer,countSov,setCountSov,setCountWer,msg, fraction, setAny }) => {
   const [ready1, setReady1] = useState(true);
   const [ready13, setReady13] = useState<boolean>(true);
   const [ready12, setReady12] = useState(true);
   const [countr, setCountr] = useState(0);
   const [countr1, setCountr1] = useState(0);
let s1 = 1
let h: any  
console.log(msg)
let h1 = msg.filter((tank) => tank.count <=0 && tank.order === "bliz" ? tank.order = "none" : tank)
let h3 = h1.filter((tank) => tank.count <=0 && tank.tank_attack_bli === 1 ? tank.tank_attack_bli = 0 : tank)
let location = useParams()
let location11 = useLocation()
let  s = msg.filter((cell) => cell.team === location11.state.fraction)
console.log(s)
console.log(location11.state.fraction)

useEffect(() => {
 

  
  setReady13(true)


  
 
 
      
},[countr1])


const onClick = () => {
  setCounter1(countr+1)
s9 =[]
hhh = []
for(let j = 1; j <= 10; j++) {

  hhh.push({id:j,tankNum:[0]})
}
for(let j =0; j <10;j++) {
  for(let i =0; i <13;i++) {

    hhh[j].tankNum[i] = (Math.floor(Math.random() * 6 + 1 ))
    
    
    }



}

 


 


console.log(hhh)






  
  
 

  
  // location11.state.create === true


  setCount(count+1)
  setReady12(ready12 === true ? false : true)
   const soket = new WebSocket('ws://aot.artofvictory.ru:8080/ws')
    soket.onopen = () => {
      if (location11.state.create === true) {
       s.map((tank) => 
        { if (tank.order === "bliz") {
          soket.send(JSON.stringify({
            method:'bliz_order_play',
            order_tank: tank,
            tankNum:hhh,
            idTank:tank.id,
            order_name: "bliz",
            lobby: h1 ,
            id: location.id,
            
    
          }))




        }
         
        
        
        
        })
       

      
      soket.send(JSON.stringify({
        method:'ready_lobby_play',
        lobby: h1 ,
        id: location.id,
        ready:  ready13,
        count1: countr,
        loc:location11.state.fraction
     }
    )) 

      
         } else  {
          s.map((tank) => 
          { if (tank.order === "bliz") {
            soket.send(JSON.stringify({
              method:'bliz_order_play',
              order_tank: tank,
              tankNum:hhh,
              idTank:tank.id,
              order_name: "bliz",
              lobby: h1 ,
              id: location.id,
              
      
            }))




          }
           
          
          
          
          } )
           soket.send(JSON.stringify({
            method:'ready_lobby_play1',
            lobby: h1  ,

            id: location.id,
            ready:  ready13 ,
            count1: countr,
            loc:location11.state.fraction
    
          }))
         
    
          
 
     
        }



        }
      
   soket.onmessage = (event) => {
    
    let s = JSON.parse(event.data)
    console.log(s)
      h = s
    
      console.log(setAny)
          setTimeout(() => { return h.players[1].ready_play === h.players[0].ready_play ? setCountr1(countr1+1) : undefined  },200)
 

         
      setAny(s)
     
   }
   setCountr(countr+1)
  
  console.log(h)
  let ssss: boolean
  setReady13(ready13 ===false  ? true:false)
 
 
  onClickAdd()
  setActiveTeam(location11.state.fraction)

      

     

      

      let baseDef: number | undefined
  const sueta = dataTank.map((tank) => {const sueta1 = dataFieldAll[1].dataField4[tank.coordinates.col-1].sections[tank.coordinates.row-1].baseDefend?.tanks 
  
    if(sueta1 === undefined) {
     baseDef = 0 

} else {  baseDef =sueta1}          
  
return baseDef  }  )
  console.log(sueta)
     
       const newDataTanks = dataTank.map((tank) => {
            tank.actions.move = tank.afterStepData.move
            tank.actions.defend = tank.actions.defend  >= tank.afterStepData.defend + sueta[tank.id-1] ? tank.actions.defend : tank.afterStepData.defend 
            tank.activeTank = true
            return tank
        })
        VisibleReady(2)
        setDataTanks(newDataTanks)
        setCountSov(countr)

      
        
         
   
if (location11.state.cart === "Танковый бой") {
  const newDataTanks1 = dataTank.filter((tank) => 
    tank.coordinates.col === 6 && tank.count > 0 
   
    
)  

const newDataTanks10 = newDataTanks1.every(item => item.team === newDataTanks1[0].team)

  if (newDataTanks10 && newDataTanks1.length >= 1  ? newDataTanks1[0].team === 1 : false  ? newDataTanks1[0].team === 1 :false  ){setCountWer(countWer+1);setCountSov(0)}  else if (newDataTanks10 && newDataTanks1.length >= 1  ? newDataTanks1[0].team === 2 : false) {setCountSov(countSov+1);setCountWer(0)} else {setCountSov(0);setCountWer(0)}
             
   
      } else if (location11.state.cart === "Вторжение") {
        
       


      } else if (location11.state.cart === "Окружение") {

      



      }

}
     


  return (
    <div className={style.block}>
     
      <div className={style.count}>номер хода : { ready13! ? countr : countr-1 }</div>
      <button disabled={!ready13} onClick={onClick} className={style.nextTeam}>{   ready13    ?  "Следующий ход"  :  "Ждём игрока..."   }</button>
     
    </div>
  );
};
