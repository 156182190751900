import pz from "../img/unit_pz2 - Copy.png";
import t26_USSR from "../img/unit_t-26k - Copy.png";
import t34 from "../img/t34 - Copy.png";
import pz3g from "../img/pz3gg - Copy.png";

import pontonReady from "../img/pontonReady.png"
import ponton3 from "../img/ponton2.png"
import ponton2 from "../img/ponton1.png"
import ponton1 from "../img/ponton.png"
import eji1 from "../img/eji1.png"
import eji2 from "../img/eji2.png"
import dot from "../img/dot.png"
import mine from "../img/mines.png"



import pehSov from "../img/infantry_USSR1.png";
import pehWer from "../img/infanrty_german.png";
import bt7 from "../img/bt7.png";
import ptSov45mm from "../img/pt45mmSov.png";
import zis5 from "../img/zis5.png";
import opelBlitc from "../img/opel_blitz.png";
import mg34 from "../img/mg34.png";
import maxim from "../img/maxim.png";
import saperWer from "../img/saper_german.png";
import po2 from "../img/po2.png";
import ju87b from "../img/ju87b.png";

import pz1 from "../img/unit_pz2.png";
import t26_USSR1 from "../img/unit_t-26k.png";
import t341 from "../img/t34.png";
import pz3g1 from "../img/pz3gg.png";

import pehSov1 from "../img/infantry_USSR3 - Copy.png";
import pehWer1 from "../img/infanrty_german1.png";
import bt71 from "../img/bt71.png";
import ptSov45mm1 from "../img/pt45mmSov1.png";
import zis51 from "../img/zis51.png";
import opelBlitc1 from "../img/opel_blitz1.png";
import mg341 from "../img/mg341.png";
import maxim1 from "../img/maxim1.png";
import saperWer1 from "../img/saper_german1.png";

import pz2 from "../img/unit_pz21.png";
import t26_USSR2 from "../img/unit_t-26k1.png";
import t342 from "../img/t341.png";
import pz3g2 from "../img/pz3gg1.png";

import pehSov2 from "../img/infantry_USSR2 - Copy.png";
import pehWer2 from "../img/infanrty_german2.png";
import bt72 from "../img/bt72.png";
import ptSov45mm2 from "../img/pt45mmSov2.png";
import zis52 from "../img/zis52.png";
import opelBlitc2 from "../img/opel_blitz2.png";
import mg342 from "../img/mg342.png";
import maxim2 from "../img/maxim2.png";
import saperWer2 from "../img/saper_german2.png";

import doroga1 from "../img/doroga_new1.png"
import doroga2 from "../img/doroga_new2.png"
import doroga3 from "../img/doroga_new3.png"
import doroga4 from "../img/doroga_new4.png"
import doroga5 from "../img/doroga_new5.png"
import doroga6 from "../img/doroga_new6.png"
import doroga7 from "../img/doroga_new7.png"
import doroga8 from "../img/doroga_new8.png"
import doroga9 from "../img/doroga_new9.png"
import doroga10 from "../img/doroga_new10.png"
import doroga11 from "../img/doroga_new11.png"
import doroga12 from "../img/field1.png"
import boloto from "../img/boloto.png"
import derev from "../img/derev.png"


import cellVeryHard from "../img/les.png"
import cellLight from "../img/field2.png"
import cellMiddle from "../img/field3.png"
import cellHard from "../img/fieldBust.png"
import cellveryMiddle from "../img/test1.png"
// import test from "../img/les.png"

export const sections = {

  hard:{
    img:cellHard, kef: 1.25,  baseDefend: { tanks:0,  humans: 1 }, visible: false
  
  },
  veryHard:{
      img: cellVeryHard, kef: 3, baseDefend: {tanks:1,humans:2}, visible: false
  },
  light:{
    img:cellLight, kef:0.75, baseDefend: { tanks:0,  humans: 0 }, visible: true
  },
  middle:{
    img:cellMiddle, kef: 1, baseDefend: { tanks:0,  humans: 0 }, visible: true
  },
  veryMiddle:{
      img: cellveryMiddle, kef: 2, baseDefend: { tanks: 1, humans: 1 },visible: false
  },
  boloto:{
    img: boloto, kef: 1000, baseDefend: { tanks: 0, humans: 0 },visible: true
},
light1:{
  img:cellLight, kef:0.75, baseDefend: { tanks:0,  humans: 0 }, visible: true
},
light2:{
  img:doroga1, kef:0.75, baseDefend: { tanks:0,  humans: 0 }, visible: true
},
light3:{
  img:doroga2, kef:0.75, baseDefend: { tanks:0,  humans: 0 }, visible: true
},
light4:{
  img:doroga3, kef:0.75, baseDefend: { tanks:0,  humans: 0 }, visible: true
},
light5:{
  img:doroga4, kef:0.75, baseDefend: { tanks:0,  humans: 0 }, visible: true
},
light6:{
  img:doroga5, kef:0.75, baseDefend: { tanks:0,  humans: 0 }, visible: true
},
light7:{
  img:doroga6, kef:0.75, baseDefend: { tanks:0,  humans: 0 }, visible: true
},
light8:{
  img:doroga7, kef:0.75, baseDefend: { tanks:0,  humans: 0 }, visible: true
},
light9:{
  img:doroga8, kef:0.75, baseDefend: { tanks:0,  humans: 0 }, visible: true
},
light10:{
  img:doroga9, kef:0.75, baseDefend: { tanks:0,  humans: 0 }, visible: true
},
light11:{
  img:doroga10, kef:0.75, baseDefend: { tanks:0,  humans: 0 }, visible: true
},
light12:{
  img:doroga11, kef:0.75, baseDefend: { tanks:0,  humans: 0 }, visible: true
},
light13:{
  img:doroga12, kef:0.75, baseDefend: { tanks:0,  humans: 0 }, visible: true
},
}




export const dataField = [ 
  {
    name: "column1",
        sections: [
       { id: 0, col: 1, row: 1, kef: sections.veryMiddle.kef, img: sections.veryMiddle.img, baseDefend: sections.veryMiddle.baseDefend, visible: sections.veryMiddle.visible },
      { id: 1, col: 1, row: 2,  kef: sections.middle.kef, img:sections.middle.img, baseDefend: sections.middle.baseDefend,  visible: sections.middle.visible },
      { id: 2, col: 1, row: 3,  kef: sections.middle.kef, img:sections.middle.img,  baseDefend: sections.middle.baseDefend, visible: sections.middle.visible },
      { id: 3, col: 1, row: 4, kef: sections.hard.kef, img:sections.hard.img,  baseDefend: sections.middle.baseDefend,visible: sections.hard.visible },
      { id: 4, col: 1, row: 5, kef: sections.veryMiddle.kef, img:sections.veryMiddle.img, baseDefend: sections.veryMiddle.baseDefend, visible: sections.veryMiddle.visible },
      { id: 5, col: 1, row: 6, kef: sections.hard.kef, img:sections.hard.img, baseDefend: sections.hard.baseDefend, visible: sections.hard.visible},
      { id: 6, col: 1, row: 7, kef: sections.hard.kef, img:sections.hard.img, baseDefend: sections.hard.baseDefend, visible: sections.hard.visible },
      { id: 7, col: 1, row: 8, kef: sections.hard.kef, img:sections.hard.img, baseDefend: sections.hard.baseDefend, visible: sections.hard.visible},


    ],
    kef: 1,
    type: 1,
  },
  {
    name: "column2",
    sections: [
      { id: 8, col: 2, row: 1,  kef:  sections.middle.kef, img:sections.middle.img,  baseDefend: sections.middle.baseDefend, visible: sections.middle.visible },
      { id: 9, col: 2, row: 2, kef: sections.veryMiddle.kef, img:sections.veryMiddle.img,baseDefend: sections.veryMiddle.baseDefend, visible: sections.veryMiddle.visible },
      { id: 10, col: 2, row: 3,kef: sections.middle.kef, img:sections.middle.img,  baseDefend: sections.middle.baseDefend, visible: sections.middle.visible},
      { id: 11, col: 2, row: 4, kef: sections.hard.kef, img:sections.hard.img,baseDefend: sections.hard.baseDefend, visible: sections.hard.visible },
      { id: 12, col: 2, row: 5, kef: sections.middle.kef, img:sections.middle.img,  baseDefend: sections.middle.baseDefend, visible: sections.middle.visible },
      { id: 13, col: 2, row: 6, kef: sections.veryMiddle.kef, img:sections.veryMiddle.img, baseDefend: sections.veryMiddle.baseDefend, visible: sections.hard.visible },
      { id: 14, col: 2, row: 7, kef: sections.veryMiddle.kef, img:sections.veryMiddle.img, baseDefend: sections.veryMiddle.baseDefend, visible: sections.veryMiddle.visible },
      { id: 15, col: 2, row: 8, kef: sections.veryMiddle.kef, img:sections.veryMiddle.img, baseDefend: sections.veryMiddle.baseDefend, visible: sections.veryMiddle.visible },




    ],
    kef: 2,
    type: 2,
  },
  {
    name: "column3",
    sections: [
      { id: 16, col: 3, row: 1, kef: sections.veryMiddle.kef, img:sections.veryMiddle.img, baseDefend: sections.veryMiddle.baseDefend, visible: sections.veryMiddle.visible},
      { id: 17, col: 3, row: 2,  kef: sections.hard.kef, img:sections.hard.img, baseDefend: sections.hard.baseDefend, visible: sections.hard.visible },
      { id: 18, col: 3, row: 3, kef: sections.hard.kef, img:sections.hard.img, baseDefend: sections.hard.baseDefend, visible: sections.hard.visible},
      { id: 19, col: 3, row: 4,  kef: sections.veryMiddle.kef, img:sections.veryMiddle.img, baseDefend: sections.veryMiddle.baseDefend, visible: sections.veryMiddle.visible},
      { id: 20, col: 3, row: 5,  kef: sections.veryMiddle.kef, img:sections.veryMiddle.img, baseDefend: sections.veryMiddle.baseDefend, visible: sections.veryHard.visible},
      { id: 21, col: 3, row: 6,  kef: sections.hard.kef, img:sections.hard.img, baseDefend: sections.hard.baseDefend, visible: sections.hard.visible},
      { id: 22, col: 3, row: 7,  kef: sections.middle.kef, img:sections.middle.img,  baseDefend: sections.middle.baseDefend, visible: sections.middle.visible },
      { id: 23, col: 3, row: 8,  kef: sections.middle.kef, img:sections.middle.img,  baseDefend: sections.middle.baseDefend, visible: sections.middle.visible },

    ],
    kef: 3,
    type: 1,
  },
  {
    name: "column4",
    sections: [
      { id: 24, col: 4, row: 1,kef: sections.hard.kef, img:sections.hard.img, baseDefend: sections.hard.baseDefend, visible: sections.hard.visible},
      { id: 25, col: 4, row: 2,  kef: sections.veryMiddle.kef, img:sections.veryMiddle.img, baseDefend: sections.veryMiddle.baseDefend, visible: sections.veryMiddle.visible },
      { id: 26, col: 4, row: 3, kef: sections.middle.kef, img:sections.middle.img,  baseDefend: sections.middle.baseDefend, visible: sections.middle.visible},
      { id: 27, col: 4, row: 4, kef: sections.hard.kef, img:sections.hard.img, baseDefend: sections.hard.baseDefend, visible: sections.hard.visible},
      { id: 28, col: 4, row: 5, kef: sections.hard.kef, img:sections.hard.img, baseDefend: sections.hard.baseDefend, visible: sections.hard.visible},
      { id: 29, col: 4, row: 6, kef: sections.middle.kef, img:sections.middle.img,  baseDefend: sections.middle.baseDefend, visible: sections.middle.visible},
      { id: 30, col: 4, row: 7, kef: sections.hard.kef, img:sections.hard.img, baseDefend: sections.hard.baseDefend, visible: sections.hard.visible },
      { id: 31, col: 4, row: 8, kef: sections.middle.kef, img:sections.middle.img,  baseDefend: sections.middle.baseDefend, visible: sections.middle.visible },

    ],
    kef: 4,
    type: 2,
  },
  {
    name: "column5",
    sections: [
      { id: 32, col: 5, row: 1,  kef: sections.veryMiddle.kef, img:sections.veryMiddle.img , baseDefend: sections.veryMiddle.baseDefend, visible: sections.veryMiddle.visible},
      { id: 33, col: 5, row: 2, kef: sections.veryHard.kef, img:sections.veryHard.img,  baseDefend: sections.veryHard.baseDefend, visible: sections.hard.visible},
      { id: 34, col: 5, row: 3, kef: sections.hard.kef, img:sections.hard.img, baseDefend: sections.hard.baseDefend, visible: sections.hard.visible },
      { id: 35, col: 5, row: 4,  kef: sections.veryHard.kef, img:sections.veryHard.img,  baseDefend: sections.veryHard.baseDefend, visible: sections.hard.visible},
      { id: 36, col: 5, row: 5,  kef: sections.veryMiddle.kef, img:sections.veryMiddle.img, baseDefend: sections.veryMiddle.baseDefend, visible: sections.hard.visible},
      { id: 37, col: 5, row: 6, kef: sections.hard.kef, img:sections.hard.img, baseDefend: sections.hard.baseDefend, visible: sections.hard.visible },
      { id: 38, col: 5, row: 7,  kef: sections.veryMiddle.kef, img:sections.veryMiddle.img, baseDefend: sections.veryMiddle.baseDefend, visible: sections.hard.visible },
      { id: 39, col: 5, row: 8, kef: sections.hard.kef, img:sections.hard.img, baseDefend: sections.hard.baseDefend, visible: sections.hard.visible},

    ],
    kef: 5,
    type: 1,
  },
  {
    name: "column6",
    sections: [
      { id: 40, col: 6, row: 1,kef: sections.light.kef, img:sections.light.img,  baseDefend: sections.middle.baseDefend, visible: sections.light.visible },
      { id: 41, col: 6, row: 2, kef: sections.light.kef, img:sections.light.img,  baseDefend: sections.middle.baseDefend, visible: sections.light.visible },
      { id: 42, col: 6, row: 3,kef: sections.light.kef, img:sections.light.img,  baseDefend: sections.middle.baseDefend, visible: sections.light.visible },
      { id: 43, col: 6, row: 4, kef: sections.light.kef, img:sections.light.img,  baseDefend: sections.middle.baseDefend, visible: sections.light.visible },
      { id: 44, col: 6, row: 5, kef: sections.light.kef, img:sections.light.img,  baseDefend: sections.middle.baseDefend, visible: sections.middle.visible},
      { id: 45, col: 6, row: 6, kef: sections.light.kef, img:sections.light.img,  baseDefend: sections.middle.baseDefend, visible: sections.light.visible },
      { id: 46, col: 6, row: 7, kef: sections.light.kef, img:sections.light.img,  baseDefend: sections.middle.baseDefend, visible: sections.light.visible },
      { id: 47, col: 6, row: 8, kef: sections.light.kef, img:sections.light.img,  baseDefend: sections.middle.baseDefend, visible: sections.light.visible },

    ],
    kef: 6,
    type: 2,
  },
  {
    name: "column7",
    sections: [
      { id: 48, col: 7, row: 1, kef: sections.veryMiddle.kef, img:sections.veryMiddle.img, baseDefend: sections.veryMiddle.baseDefend, visible: sections.veryMiddle.visible },
      { id: 49, col: 7, row: 2, kef: sections.hard.kef, img:sections.hard.img, baseDefend: sections.hard.baseDefend, visible: sections.hard.visible },
      { id: 50, col: 7, row: 3, kef: sections.hard.kef, img:sections.hard.img , baseDefend: sections.hard.baseDefend, visible: sections.hard.visible},
      { id: 51, col: 7, row: 4, kef: sections.middle.kef, img:sections.middle.img,  baseDefend: sections.middle.baseDefend, visible: sections.middle.visible},
      { id: 52, col: 7, row: 5, kef: sections.hard.kef, img:sections.hard.img, baseDefend: sections.hard.baseDefend, visible: sections.hard.visible },
      { id: 53, col: 7, row: 6,kef: sections.veryMiddle.kef, img:sections.veryMiddle.img, baseDefend: sections.veryMiddle.baseDefend, visible: sections.hard.visible},
      { id: 54, col: 7, row: 7, kef: sections.veryMiddle.kef, img:sections.veryMiddle.img, baseDefend: sections.veryMiddle.baseDefend, visible: sections.hard.visible },
      { id: 55, col: 7, row: 8, kef: sections.hard.kef, img:sections.hard.img, baseDefend: sections.hard.baseDefend, visible: sections.hard.visible },

    ],
    kef: 7,
    type: 1,
  },
  {
    name: "column8",
    sections: [
      { id: 56, col: 8, row: 1,  kef: sections.hard.kef, img:sections.hard.img, baseDefend: sections.hard.baseDefend, visible: sections.hard.visible },
      { id: 57, col: 8, row: 2, kef: sections.veryHard.kef, img:sections.veryHard.img, baseDefend: sections.veryHard.baseDefend, visible: sections.veryHard.visible },
      { id: 58, col: 8, row: 3, kef: sections.veryMiddle.kef, img:sections.veryMiddle.img, baseDefend: sections.veryMiddle.baseDefend, visible: sections.hard.visible },
      { id: 59, col: 8, row: 4, kef: sections.veryMiddle.kef, img:sections.veryMiddle.img, baseDefend: sections.veryMiddle.baseDefend, visible: sections.hard.visible},
      { id: 60, col: 8, row: 5,  kef: sections.hard.kef, img:sections.hard.img , baseDefend: sections.hard.baseDefend, visible: sections.hard.visible},
      { id: 61, col: 8, row: 6,  kef: sections.hard.kef, img:sections.hard.img , baseDefend: sections.hard.baseDefend, visible: sections.hard.visible},
      { id: 62, col: 8, row: 7,  kef: sections.hard.kef, img:sections.hard.img , baseDefend: sections.hard.baseDefend, visible: sections.hard.visible},
      { id: 63, col: 8, row: 8, kef: sections.middle.kef, img:sections.middle.img,  baseDefend: sections.middle.baseDefend, visible: sections.middle.visible  },

    ],
    kef: 8,
    type: 2,
  },
  {
    name: "column9",
    sections: [
      { id:  64, col: 9, row: 1, kef: sections.middle.kef, img:sections.middle.img,  baseDefend: sections.middle.baseDefend, visible: sections.middle.visible  },
      { id: 65, col: 9, row: 2, kef: sections.veryMiddle.kef, img:sections.veryMiddle.img, baseDefend: sections.veryMiddle.baseDefend, visible: sections.veryMiddle.visible },
      { id: 66, col: 9, row: 3, kef: sections.veryMiddle.kef, img:sections.veryMiddle.img, baseDefend: sections.veryMiddle.baseDefend, visible: sections.veryMiddle.visible},
      { id: 67, col: 9, row: 4, kef: sections.hard.kef, img:sections.hard.img, baseDefend: sections.hard.baseDefend, visible: sections.hard.visible },
      { id: 68, col: 9, row: 5, kef: sections.veryMiddle.kef, img:sections.veryMiddle.img, baseDefend: sections.veryMiddle.baseDefend, visible: sections.veryMiddle.visible },
      { id: 69, col: 9, row: 6, kef: sections.hard.kef, img:sections.hard.img, baseDefend: sections.hard.baseDefend, visible: sections.hard.visible },
      { id: 70, col: 9, row: 7, kef: sections.hard.kef, img:sections.hard.img, baseDefend: sections.hard.baseDefend, visible: sections.hard.visible },
      { id: 71, col: 9, row: 8, kef: sections.veryMiddle.kef, img:sections.veryMiddle.img, baseDefend: sections.veryMiddle.baseDefend, visible: sections.veryMiddle.visible },

    ],
    kef: 9,
    type: 1,
  },
  {
  name: "column10",
  sections: [
    { id: 72, col: 10, row: 1, kef: sections.middle.kef, img:sections.middle.img,  baseDefend: sections.middle.baseDefend, visible: sections.middle.visible  },
    { id: 73, col: 10, row: 2, kef: sections.veryMiddle.kef, img:sections.veryMiddle.img, baseDefend: sections.veryMiddle.baseDefend, visible: sections.veryMiddle.visible },
    { id: 74, col: 10, row: 3, kef: sections.veryMiddle.kef, img:sections.veryMiddle.img, baseDefend: sections.veryMiddle.baseDefend, visible: sections.veryMiddle.visible},
    { id: 75, col: 10, row: 4, kef: sections.hard.kef, img:sections.hard.img , baseDefend: sections.hard.baseDefend, visible: sections.hard.visible},
    { id: 76, col: 10, row: 5, kef: sections.veryMiddle.kef, img:sections.veryMiddle.img , baseDefend: sections.veryMiddle.baseDefend, visible: sections.veryMiddle.visible},
    { id: 77, col: 10, row: 6, kef: sections.hard.kef, img:sections.hard.img, baseDefend: sections.hard.baseDefend, visible: sections.hard.visible },
    { id: 78, col: 10, row: 7, kef: sections.hard.kef, img:sections.hard.img, baseDefend: sections.hard.baseDefend, visible: sections.hard.visible },
    { id: 79, col: 10, row: 8, kef: sections.veryMiddle.kef, img:sections.veryMiddle.img, baseDefend: sections.veryMiddle.baseDefend, visible: sections.veryMiddle.visible },

  ],
  kef: 10,
  type: 2,
},

{
  name: "column11",
  sections: [
    { id: 80, col: 11, row: 1, kef: sections.middle.kef, img:sections.middle.img,  baseDefend: sections.middle.baseDefend, visible: sections.middle.visible  },
    { id: 81, col: 11, row: 2, kef: sections.veryMiddle.kef, img:sections.veryMiddle.img, baseDefend: sections.veryMiddle.baseDefend, visible: sections.veryMiddle.visible   },
    { id: 82, col: 11, row: 3, kef: sections.veryMiddle.kef, img:sections.veryMiddle.img, baseDefend: sections.veryMiddle.baseDefend, visible: sections.veryMiddle.visible  },
    { id: 83, col: 11, row: 4, kef: sections.hard.kef, img:sections.hard.img, baseDefend: sections.hard.baseDefend, visible: sections.hard.visible   },
    { id: 84, col: 11, row: 5, kef: sections.veryMiddle.kef, img:sections.veryMiddle.img, baseDefend: sections.veryMiddle.baseDefend, visible: sections.veryMiddle.visible   },
    { id: 85, col: 11, row: 6, kef: sections.hard.kef, img:sections.hard.img , baseDefend: sections.hard.baseDefend, visible: sections.hard.visible  },
    { id: 86, col: 11, row: 7, kef: sections.hard.kef, img:sections.hard.img, baseDefend: sections.hard.baseDefend, visible: sections.hard.visible   },
    { id: 87, col: 11, row: 8, kef: sections.veryMiddle.kef, img:sections.veryMiddle.img, baseDefend: sections.veryMiddle.baseDefend, visible: sections.veryMiddle.visible   },

  ],
  kef: 11,
  type: 1,
},


];


export const dataField1 = [ 
  {
    name: "column1",
        sections: [
       { id: 0, col: 1, row: 1, kef: 1000, img: sections.veryHard.img, baseDefend: sections.veryHard.baseDefend, visible: sections.veryHard.visible },
      { id: 1, col: 1, row: 2,  kef: 1000, img: sections.veryHard.img, baseDefend: sections.veryHard.baseDefend, visible: sections.veryHard.visible },
      { id: 2, col: 1, row: 3,  kef: 1000, img: sections.veryHard.img, baseDefend: sections.veryHard.baseDefend, visible: sections.veryHard.visible},
      { id: 3, col: 1, row: 4,  kef: 1000, img: sections.veryHard.img, baseDefend: sections.veryHard.baseDefend, visible: sections.veryHard.visible },
      { id: 3, col: 1, row: 5,  kef: 1000, img: sections.veryHard.img, baseDefend: sections.veryHard.baseDefend, visible: sections.veryHard.visible },
  
  
    ],
    kef: 1,
    type: 1,
  },
  {
    name: "column2",
    sections: [
      { id: 8, col: 2, row: 1,  kef:  sections.middle.kef, img:sections.middle.img,  baseDefend: sections.middle.baseDefend, visible: sections.middle.visible },
      { id: 9, col: 2, row: 2, kef: sections.hard.kef, img:sections.hard.img,baseDefend: sections.hard.baseDefend, visible: sections.hard.visible },
      { id: 10, col: 2, row: 3,kef: sections.hard.kef, img:sections.hard.img,baseDefend: sections.hard.baseDefend, visible: sections.hard.visible},
      { id: 11, col: 2, row: 4, kef: sections.hard.kef, img:sections.hard.img,baseDefend: sections.hard.baseDefend, visible: sections.hard.visible},
      { id: 12, col: 2, row: 5, kef: sections.hard.kef, img:sections.hard.img,baseDefend: sections.hard.baseDefend, visible: sections.hard.visible},
     
  
  
  
  
    ],
    kef: 2,
    type: 2,
  },
  {
    name: "column3",
    sections: [
      { id: 16, col: 3, row: 1, kef: sections.middle.kef, img:sections.middle.img, baseDefend: sections.middle.baseDefend, visible: sections.middle.visible},
      { id: 17, col: 3, row: 2,  kef: sections.veryMiddle.kef, img:sections.veryMiddle.img, baseDefend: sections.veryMiddle.baseDefend, visible: sections.veryMiddle.visible },
      { id: 18, col: 3, row: 3, kef: sections.veryMiddle.kef, img:sections.veryMiddle.img, baseDefend: sections.veryMiddle.baseDefend, visible: sections.veryMiddle.visible},
      { id: 19, col: 3, row: 4,  kef: sections.veryMiddle.kef, img:sections.veryMiddle.img, baseDefend: sections.veryMiddle.baseDefend, visible: sections.veryMiddle.visible},
      { id: 20, col: 3, row: 5,  kef: sections.middle.kef, img:sections.middle.img, baseDefend: sections.middle.baseDefend, visible: sections.middle.visible},
   
  
    ],
    kef: 3,
    type: 1,
  },
  {
    name: "column4",
    sections: [
      { id: 24, col: 4, row: 1,kef: sections.light.kef, img:sections.light.img, baseDefend: sections.light.baseDefend, visible: sections.light.visible},
      { id: 25, col: 4, row: 2,  kef: sections.light.kef, img:sections.light.img, baseDefend: sections.light.baseDefend, visible: sections.light.visible },
      { id: 26, col: 4, row: 3, kef: sections.light.kef, img:sections.light.img, baseDefend: sections.light.baseDefend, visible: sections.light.visible},
      { id: 27, col: 4, row: 4, kef: sections.light.kef, img:sections.light.img, baseDefend: sections.light.baseDefend, visible: sections.light.visible},
      { id: 28, col: 4, row: 5, kef: sections.light.kef, img:sections.light.img, baseDefend: sections.light.baseDefend, visible: sections.light.visible},
  
    ],
    kef: 4,
    type: 2,
  },
  {
    name: "column5",
    sections: [
      { id: 32, col: 5, row: 1,  kef: sections.middle.kef, img:sections.middle.img, baseDefend: sections.middle.baseDefend, visible: sections.middle.visible},
      { id: 33, col: 5, row: 2, kef:sections.veryHard.kef, img: sections.veryHard.img, baseDefend: sections.veryHard.baseDefend, visible: sections.veryHard.visible},
      { id: 34, col: 5, row: 3,  kef: sections.veryHard.kef, img: sections.veryHard.img, baseDefend: sections.veryHard.baseDefend, visible: sections.veryHard.visible },
      { id: 35, col: 5, row: 4,   kef: sections.veryHard.kef, img: sections.veryHard.img, baseDefend: sections.veryHard.baseDefend, visible: sections.veryHard.visible},
      { id: 36, col: 5, row: 5, kef: sections.middle.kef, img:sections.middle.img, baseDefend: sections.middle.baseDefend, visible: sections.middle.visible},
     
  
    ],
    kef: 5,
    type: 1,
  },
  {
    name: "column6",
    sections: [
      { id: 40, col: 6, row: 1, kef: sections.veryMiddle.kef, img:sections.veryMiddle.img, baseDefend: sections.veryMiddle.baseDefend, visible: sections.veryMiddle.visible },
      { id: 41, col: 6, row: 2,  kef: sections.veryMiddle.kef, img:sections.veryMiddle.img, baseDefend: sections.veryMiddle.baseDefend, visible: sections.veryMiddle.visible },
      { id: 42, col: 6, row: 3, kef: sections.hard.kef, img:sections.hard.img,baseDefend: sections.hard.baseDefend, visible: sections.hard.visible },
      { id: 43, col: 6, row: 4, kef: sections.veryHard.kef, img: sections.veryHard.img, baseDefend: sections.veryHard.baseDefend, visible: sections.veryHard.visible},
      { id: 44, col: 6, row: 5, kef: sections.hard.kef, img: sections.hard.img, baseDefend: sections.hard.baseDefend, visible: sections.hard.visible},
  
    ],
    kef: 6,
    type: 2,
  },
  {
    name: "column7",
    sections: [
      { id: 48, col: 7, row: 1, kef: sections.middle.kef, img:sections.middle.img,  baseDefend: sections.middle.baseDefend, visible: sections.middle.visible },
      { id: 49, col: 7, row: 2, kef: sections.middle.kef, img:sections.middle.img,  baseDefend: sections.middle.baseDefend, visible: sections.middle.visible },
      { id: 50, col: 7, row: 3, kef: sections.middle.kef, img:sections.middle.img,  baseDefend: sections.middle.baseDefend, visible: sections.middle.visible},
      { id: 51, col: 7, row: 4, kef: sections.middle.kef, img:sections.middle.img,  baseDefend: sections.middle.baseDefend, visible: sections.middle.visible},
      { id: 52, col: 7, row: 5, kef: sections.middle.kef, img:sections.middle.img,  baseDefend: sections.middle.baseDefend, visible: sections.middle.visible },
      
  
    ],
    kef: 7,
    type: 1,
  },
  {
    name: "column8",
    sections: [
      { id: 56, col: 8, row: 1,  kef: sections.middle.kef, img:sections.middle.img,  baseDefend: sections.middle.baseDefend, visible: sections.middle.visible },
      { id: 57, col: 8, row: 2, kef: sections.middle.kef, img:sections.middle.img,  baseDefend: sections.middle.baseDefend, visible: sections.middle.visible },
      { id: 58, col: 8, row: 3, kef: sections.veryMiddle.kef, img:sections.veryMiddle.img, baseDefend: sections.veryMiddle.baseDefend, visible: sections.veryMiddle.visible },
      { id: 59, col: 8, row: 4, kef: sections.middle.kef, img:sections.middle.img,  baseDefend: sections.middle.baseDefend, visible: sections.middle.visible},
      { id: 60, col: 8, row: 5,  kef: sections.middle.kef, img:sections.middle.img,  baseDefend: sections.middle.baseDefend, visible: sections.middle.visible},
     
  
    ],
    kef: 8,
    type: 2,
  },
  {
    name: "column9",
    sections: [
      { id:  64, col: 9, row: 1, kef: sections.middle.kef, img:sections.middle.img,  baseDefend: sections.middle.baseDefend, visible: sections.middle.visible  },
      { id: 65, col: 9, row: 2, kef:  sections.middle.kef, img:sections.middle.img,  baseDefend: sections.middle.baseDefend, visible: sections.middle.visible},
      { id: 66, col: 9, row: 3, kef: sections.boloto.kef, img:sections.boloto.img, baseDefend: sections.boloto.baseDefend, visible: sections.boloto.visible},
      { id: 67, col: 9, row: 4, kef: sections.veryMiddle.kef, img:sections.veryMiddle.img, baseDefend: sections.veryMiddle.baseDefend, visible: sections.veryMiddle.visible },
      { id: 68, col: 9, row: 5, kef: sections.middle.kef, img:sections.middle.img,  baseDefend: sections.middle.baseDefend, visible: sections.middle.visible },
     
  
    ],
    kef: 9,
    type: 1,
  },
  {
  name: "column10",
  sections: [
    { id: 72, col: 10, row: 1, kef: sections.light5.kef, img:sections.light5.img,  baseDefend: sections.light5.baseDefend, visible: sections.light5.visible  },
    { id: 73, col: 10, row: 2, kef: sections.boloto.kef, img:sections.boloto.img, baseDefend: sections.boloto.baseDefend, visible: sections.boloto.visible },
    { id: 74, col: 10, row: 3, kef: sections.hard.kef, img:sections.hard.img, baseDefend: sections.hard.baseDefend, visible: sections.hard.visible},
    { id: 75, col: 10, row: 4, kef: sections.light7.kef, img:sections.light7.img , baseDefend: sections.light7.baseDefend, visible: sections.light7.visible},
    { id: 76, col: 10, row: 5, kef: sections.light.kef, img:sections.light.img , baseDefend: sections.light.baseDefend, visible: sections.light.visible},
    
  
  ],
  kef: 10,
  type: 2,
  },
  
  {
  name: "column11",
  sections: [
    { id: 80, col: 11, row: 1, kef: sections.middle.kef, img:sections.middle.img,  baseDefend: sections.middle.baseDefend, visible: sections.middle.visible  },
    { id: 81, col: 11, row: 2, kef: sections.light3.kef, img:sections.light3.img, baseDefend: sections.light3.baseDefend, visible: sections.light3.visible   },
    { id: 82, col: 11, row: 3, kef: sections.boloto.kef, img:sections.boloto.img, baseDefend: sections.boloto.baseDefend, visible: sections.boloto.visible  },
    { id: 83, col: 11, row: 4, kef: sections.light4.kef, img:sections.light4.img, baseDefend: sections.light4.baseDefend, visible: sections.light4.visible   },
    { id: 84, col: 11, row: 5, kef: sections.middle.kef, img:sections.middle.img, baseDefend: sections.middle.baseDefend, visible: sections.middle.visible   },
   
  
  ],
  kef: 11,
  type: 1,
  },
  {
    name: "column12",
    sections: [
      { id: 85, col: 12, row: 1, kef: sections.hard.kef, img:sections.hard.img,  baseDefend: sections.hard.baseDefend, visible: sections.hard.visible  },
      { id: 86, col: 12, row: 2, kef: sections.light12.kef, img:sections.light12.img, baseDefend: sections.light12.baseDefend, visible: sections.light12.visible   },
      { id: 87, col: 12, row: 3, kef: sections.light6.kef, img:sections.light6.img, baseDefend: sections.light6.baseDefend, visible: sections.light6.visible  },
      { id: 88, col: 12, row: 4, kef: sections.middle.kef, img:sections.middle.img, baseDefend: sections.middle.baseDefend, visible: sections.middle.visible   },
      { id: 89, col: 12, row: 5, kef: sections.middle.kef, img:sections.middle.img, baseDefend: sections.middle.baseDefend, visible: sections.middle.visible   },
     
    
    ],
    kef: 12,
    type:2,
    },
    {
      name: "column13",
      sections: [
        { id: 90, col: 13, row: 1, kef: sections.middle.kef, img:sections.middle.img,  baseDefend: sections.middle.baseDefend, visible: sections.middle.visible  },
        { id: 91, col: 13, row: 2, kef: sections.middle.kef, img:sections.middle.img, baseDefend: sections.middle.baseDefend, visible: sections.middle.visible   },
        { id: 92, col: 13, row: 3,  kef: sections.light3.kef, img:sections.light3.img, baseDefend: sections.light3.baseDefend, visible: sections.light3.visible  },
        { id: 93, col: 13, row: 4, kef: sections.middle.kef, img:sections.middle.img, baseDefend: sections.middle.baseDefend, visible: sections.middle.visible   },
        { id: 94, col: 13, row: 5, kef: sections.middle.kef, img:sections.middle.img, baseDefend: sections.middle.baseDefend, visible: sections.middle.visible   },
       
      
      ],
      kef: 13,
      type: 1,
      },
      {
        name: "column14",
        sections: [
          { id: 95, col: 14, row: 1, kef: sections.middle.kef, img:sections.middle.img,  baseDefend: sections.middle.baseDefend, visible: sections.middle.visible  },
          { id: 96, col: 14, row: 2, kef: sections.veryMiddle.kef, img:sections.veryMiddle.img, baseDefend: sections.veryMiddle.baseDefend, visible: sections.veryMiddle.visible   },
          { id: 97, col: 14, row: 3,  kef: sections.light3.kef, img:sections.light3.img, baseDefend: sections.light3.baseDefend, visible: sections.light3.visible  },
          { id: 98, col: 14, row: 4, kef: sections.veryMiddle.kef, img:sections.veryMiddle.img, baseDefend: sections.veryMiddle.baseDefend, visible: sections.veryMiddle.visible   },
          { id: 99, col: 14, row: 5, kef: sections.veryMiddle.kef, img:sections.veryMiddle.img, baseDefend: sections.veryMiddle.baseDefend, visible: sections.veryMiddle.visible   },
         
        
        ],
        kef: 14,
        type:2,
        },
        {
          name: "column15",
          sections: [
            { id: 100, col: 15, row: 1, kef: sections.middle.kef, img:sections.middle.img,  baseDefend: sections.middle.baseDefend, visible: sections.middle.visible  },
            { id: 101, col: 15, row: 2, kef: sections.hard.kef, img:sections.hard.img, baseDefend: sections.hard.baseDefend, visible: sections.hard.visible   },
            { id: 102, col: 15, row: 3, kef: sections.hard.kef, img:sections.hard.img, baseDefend: sections.hard.baseDefend, visible: sections.hard.visible  },
            { id: 103, col: 15, row: 4,  kef: sections.light3.kef, img:sections.light3.img, baseDefend: sections.light3.baseDefend, visible: sections.light3.visible  },
            { id: 104, col: 15, row: 5, kef: sections.middle.kef, img:sections.middle.img, baseDefend: sections.middle.baseDefend, visible: sections.middle.visible   },
           
          
          ],
          kef: 15,
          type: 1,
          },
          {
            name: "column16",
            sections: [
              { id: 105, col: 16, row: 1, kef: sections.light.kef, img:sections.light.img, baseDefend: sections.light.baseDefend, visible: sections.light.visible   },
              { id: 106, col: 16, row: 2, kef: sections.light.kef, img:sections.light.img, baseDefend: sections.light.baseDefend, visible: sections.light.visible   },
              { id: 107, col: 16, row: 3,  kef: sections.light.kef, img:sections.light.img, baseDefend: sections.light.baseDefend, visible: sections.light.visible   },
              { id: 108, col: 16, row: 4, kef: sections.light2.kef, img:sections.light2.img, baseDefend: sections.light2.baseDefend, visible: sections.light2.visible   },
              { id: 109, col: 16, row: 5,  kef: sections.light.kef, img:sections.light.img, baseDefend: sections.light.baseDefend, visible: sections.light.visible    },
             
            
            ],
            kef: 16,
            type:2,
            },
            {
              name: "column17",
              sections: [
                { id: 110, col: 17, row: 1, kef: sections.middle.kef, img:sections.middle.img,  baseDefend: sections.middle.baseDefend, visible: sections.middle.visible  },
                { id: 111, col: 17, row: 2, kef: sections.hard.kef, img:sections.hard.img, baseDefend: sections.hard.baseDefend, visible: sections.hard.visible   },
                { id: 112, col: 17, row: 3, kef: sections.middle.kef, img:sections.middle.img, baseDefend: sections.middle.baseDefend, visible: sections.middle.visible  },
                { id: 113, col: 17, row: 4, kef: sections.middle.kef, img:sections.middle.img, baseDefend: sections.middle.baseDefend, visible: sections.middle.visible   },
                { id: 114, col: 17, row: 5, kef: sections.middle.kef, img:sections.middle.img, baseDefend: sections.middle.baseDefend, visible: sections.middle.visible   },
               
              
              ],
              kef: 17,
              type: 1,
              },
              {
                name: "column18",
                sections: [
                  { id: 115, col: 18, row: 1, kef: sections.middle.kef, img:sections.middle.img,  baseDefend: sections.middle.baseDefend, visible: sections.middle.visible  },
                  { id: 116, col: 18, row: 2, kef: sections.veryMiddle.kef, img:sections.veryMiddle.img, baseDefend: sections.veryMiddle.baseDefend, visible: sections.veryMiddle.visible   },
                  { id: 117, col: 18, row: 3,kef: sections.hard.kef, img:sections.hard.img, baseDefend: sections.hard.baseDefend, visible: sections.hard.visible   },
                  { id: 118, col: 18, row: 4, kef: sections.middle.kef, img:sections.middle.img, baseDefend: sections.middle.baseDefend, visible: sections.middle.visible    },
                  { id: 119, col: 18, row: 5, kef: sections.middle.kef, img:sections.middle.img, baseDefend: sections.middle.baseDefend, visible: sections.middle.visible   },
                 
                
                ],
                kef: 18,
                type:2,
                },
                {
                  name: "column19",
                  sections: [
                    { id: 120, col: 19, row: 1, kef: 1000, img: sections.veryHard.img, baseDefend: sections.veryHard.baseDefend, visible: sections.veryHard.visible },
                    { id: 121, col: 19, row: 2,  kef: 1000, img: sections.veryHard.img, baseDefend: sections.veryHard.baseDefend, visible: sections.veryHard.visible },
                    { id: 122, col: 19, row: 3,  kef: 1000, img: sections.veryHard.img, baseDefend: sections.veryHard.baseDefend, visible: sections.veryHard.visible},
                    { id: 123, col: 19, row: 4,  kef: 1000, img: sections.veryHard.img, baseDefend: sections.veryHard.baseDefend, visible: sections.veryHard.visible },
                    { id: 124, col: 19, row: 5,  kef: 1000, img: sections.veryHard.img, baseDefend: sections.veryHard.baseDefend, visible: sections.veryHard.visible },
                
                   
                  
                  ],
                  kef: 19,
                  type:1,
                  },
 
]


export const dataField2 = [ 
  {
    name: "column1",
        sections: [
       { id: 0, col: 1, row: 1, kef: 1000, img: sections.veryHard.img, baseDefend: sections.veryHard.baseDefend, visible: sections.veryHard.visible },
      { id: 1, col: 1, row: 2,  kef: 1000, img: sections.veryHard.img, baseDefend: sections.veryHard.baseDefend, visible: sections.veryHard.visible },
      { id: 2, col: 1, row: 3,  kef: 1000, img: sections.veryHard.img, baseDefend: sections.veryHard.baseDefend, visible: sections.veryHard.visible},
      { id: 3, col: 1, row: 4,  kef: 1000, img: sections.veryHard.img, baseDefend: sections.veryHard.baseDefend, visible: sections.veryHard.visible },
      { id: 3, col: 1, row: 5,  kef: 1000, img: sections.veryHard.img, baseDefend: sections.veryHard.baseDefend, visible: sections.veryHard.visible },
  
  
    ],
    kef: 1,
    type: 1,
  },
  {
    name: "column2",
    sections: [
      { id: 8, col: 2, row: 1,  kef:  sections.middle.kef, img:sections.middle.img,  baseDefend: sections.middle.baseDefend, visible: sections.middle.visible },
      { id: 9, col: 2, row: 2, kef: sections.hard.kef, img:sections.hard.img,baseDefend: sections.hard.baseDefend, visible: sections.hard.visible },
      { id: 10, col: 2, row: 3,kef: sections.veryMiddle.kef, img:sections.veryMiddle.img, baseDefend: sections.veryMiddle.baseDefend, visible: sections.veryMiddle.visible},
      { id: 11, col: 2, row: 4, kef:  sections.middle.kef, img:sections.middle.img,  baseDefend: sections.middle.baseDefend, visible: sections.middle.visible},
      { id: 12, col: 2, row: 5, kef:  sections.middle.kef, img:sections.middle.img,  baseDefend: sections.middle.baseDefend, visible: sections.middle.visible},
     
  
  
  
  
    ],
    kef: 2,
    type: 2,
  },
  {
    name: "column3",
    sections: [
      { id: 16, col: 3, row: 1, kef: sections.middle.kef, img:sections.middle.img, baseDefend: sections.middle.baseDefend, visible: sections.middle.visible},
      { id: 17, col: 3, row: 2,  kef:  sections.middle.kef, img:sections.middle.img,  baseDefend: sections.middle.baseDefend, visible: sections.middle.visible },
      { id: 18, col: 3, row: 3, kef:  sections.middle.kef, img:sections.middle.img,  baseDefend: sections.middle.baseDefend, visible: sections.middle.visible},
      { id: 19, col: 3, row: 4, kef: sections.hard.kef, img:sections.hard.img,baseDefend: sections.hard.baseDefend, visible: sections.hard.visible},
      { id: 20, col: 3, row: 5,  kef: sections.middle.kef, img:sections.middle.img, baseDefend: sections.middle.baseDefend, visible: sections.middle.visible},
   
  
    ],
    kef: 3,
    type: 1,
  },
  {
    name: "column4",
    sections: [
      { id: 24, col: 4, row: 1,kef: sections.light8.kef, img:sections.light8.img, baseDefend: sections.light8.baseDefend, visible: sections.light8.visible},
      { id: 25, col: 4, row: 2,  kef: sections.light.kef, img:sections.light.img, baseDefend: sections.light.baseDefend, visible: sections.light.visible },
      { id: 26, col: 4, row: 3, kef: sections.light.kef, img:sections.light.img, baseDefend: sections.light.baseDefend, visible: sections.light.visible},
      { id: 27, col: 4, row: 4, kef: sections.light.kef, img:sections.light.img, baseDefend: sections.light.baseDefend, visible: sections.light.visible},
      { id: 28, col: 4, row: 5, kef: sections.light.kef, img:sections.light.img, baseDefend: sections.light.baseDefend, visible: sections.light.visible},
  
    ],
    kef: 4,
    type: 2,
  },
  {
    name: "column5",
    sections: [
      { id: 32, col: 5, row: 1,  kef: sections.middle.kef, img:sections.middle.img, baseDefend: sections.middle.baseDefend, visible: sections.middle.visible},
      { id: 33, col: 5, row: 2, kef:sections.light3.kef, img: sections.light3.img, baseDefend: sections.light3.baseDefend, visible: sections.light3.visible},
      { id: 34, col: 5, row: 3,  kef: sections.hard.kef, img:sections.hard.img,baseDefend: sections.hard.baseDefend, visible: sections.hard.visible },
      { id: 35, col: 5, row: 4,  kef: sections.hard.kef, img:sections.hard.img,baseDefend: sections.hard.baseDefend, visible: sections.hard.visible},
      { id: 36, col: 5, row: 5, kef: sections.hard.kef, img:sections.hard.img, baseDefend: sections.hard.baseDefend, visible: sections.hard.visible},
     
  
    ],
    kef: 5,
    type: 1,
  },
  {
    name: "column6",
    sections: [
      { id: 40, col: 6, row: 1, kef: sections.veryMiddle.kef, img:sections.veryMiddle.img, baseDefend: sections.veryMiddle.baseDefend, visible: sections.veryMiddle.visible },
      { id: 41, col: 6, row: 2,  kef: sections.light3.kef, img:sections.light3.img, baseDefend: sections.light3.baseDefend, visible: sections.light3.visible },
      { id: 42, col: 6, row: 3,kef: sections.veryMiddle.kef, img:sections.veryMiddle.img, baseDefend: sections.veryMiddle.baseDefend, visible: sections.veryMiddle.visible },
      { id: 43, col: 6, row: 4, kef:  sections.middle.kef, img:sections.middle.img,  baseDefend: sections.middle.baseDefend, visible: sections.middle.visible},
      { id: 44, col: 6, row: 5, kef:  sections.middle.kef, img:sections.middle.img,  baseDefend: sections.middle.baseDefend, visible: sections.middle.visible},
  
    ],
    kef: 6,
    type: 2,
  },
  {
    name: "column7",
    sections: [
      { id: 48, col: 7, row: 1, kef: sections.middle.kef, img:sections.middle.img,  baseDefend: sections.middle.baseDefend, visible: sections.middle.visible },
      { id: 49, col: 7, row: 2, kef: sections.middle.kef, img:sections.middle.img,  baseDefend: sections.middle.baseDefend, visible: sections.middle.visible },
      { id: 50, col: 7, row: 3, kef: sections.light3.kef, img:sections.light3.img,  baseDefend: sections.light3.baseDefend, visible: sections.light3.visible},
      { id: 51, col: 7, row: 4, kef: sections.middle.kef, img:sections.middle.img,  baseDefend: sections.middle.baseDefend, visible: sections.middle.visible},
      { id: 52, col: 7, row: 5, kef: sections.middle.kef, img:sections.middle.img,  baseDefend: sections.middle.baseDefend, visible: sections.middle.visible },
      
  
    ],
    kef: 7,
    type: 1,
  },
  {
    name: "column8",
    sections: [
      { id: 56, col: 8, row: 1,  kef: sections.veryHard.kef, img:sections.veryHard.img,  baseDefend: sections.veryHard.baseDefend, visible: sections.veryHard.visible },
      { id: 57, col: 8, row: 2, kef: sections.hard.kef, img:sections.hard.img,baseDefend: sections.hard.baseDefend, visible: sections.hard.visible},
      { id: 58, col: 8, row: 3, kef: sections.light10.kef, img:sections.light10.img, baseDefend: sections.light10.baseDefend, visible: sections.light10.visible },
      { id: 59, col: 8, row: 4,kef: sections.hard.kef, img:sections.hard.img,baseDefend: sections.hard.baseDefend, visible: sections.hard.visible},
      { id: 60, col: 8, row: 5,  kef: sections.middle.kef, img:sections.middle.img,  baseDefend: sections.middle.baseDefend, visible: sections.middle.visible},
     
  
    ],
    kef: 8,
    type: 2,
  },
  {
    name: "column9",
    sections: [
      { id:  64, col: 9, row: 1, kef: sections.middle.kef, img:sections.middle.img,  baseDefend: sections.middle.baseDefend, visible: sections.middle.visible  },
      { id: 65, col: 9, row: 2, kef: sections.veryMiddle.kef, img:sections.veryMiddle.img, baseDefend: sections.veryMiddle.baseDefend, visible: sections.veryMiddle.visible},
      { id: 66, col: 9, row: 3, kef: sections.light9.kef, img:sections.light9.img, baseDefend: sections.light9.baseDefend, visible: sections.light9.visible},
      { id: 67, col: 9, row: 4, kef: sections.veryMiddle.kef, img:sections.veryMiddle.img, baseDefend: sections.veryMiddle.baseDefend, visible: sections.veryMiddle.visible },
      { id: 68, col: 9, row: 5, kef: sections.middle.kef, img:sections.middle.img,  baseDefend: sections.middle.baseDefend, visible: sections.middle.visible },
     
  
    ],
    kef: 9,
    type: 1,
  },
  {
  name: "column10",
  sections: [
    { id: 72, col: 10, row: 1, kef: sections.light5.kef, img:sections.light.img,  baseDefend: sections.light5.baseDefend, visible: sections.light5.visible  },
    { id: 73, col: 10, row: 2, kef: sections.light.kef, img:sections.light.img, baseDefend: sections.light.baseDefend, visible: sections.light.visible },
    { id: 74, col: 10, row: 3, kef: sections.light2.kef, img:sections.light2.img,baseDefend: sections.light2.baseDefend, visible: sections.light2.visible},
    { id: 75, col: 10, row: 4, kef: sections.light7.kef, img:sections.light.img , baseDefend: sections.light7.baseDefend, visible: sections.light7.visible},
    { id: 76, col: 10, row: 5, kef: sections.light.kef, img:sections.light.img , baseDefend: sections.light.baseDefend, visible: sections.light.visible},
    
  
  ],
  kef: 10,
  type: 2,
  },
  
  {
  name: "column11",
  sections: [
    { id: 80, col: 11, row: 1, kef: sections.middle.kef, img:sections.middle.img,  baseDefend: sections.middle.baseDefend, visible: sections.middle.visible  },
    { id: 81, col: 11, row: 2, kef: sections.veryHard.kef, img:sections.veryHard.img,  baseDefend: sections.veryHard.baseDefend, visible: sections.veryHard.visible },
    { id: 82, col: 11, row: 3, kef: sections.veryMiddle.kef, img:sections.veryMiddle.img, baseDefend: sections.veryMiddle.baseDefend, visible: sections.veryMiddle.visible },
    { id: 83, col: 11, row: 4,kef: sections.veryMiddle.kef, img:sections.veryMiddle.img, baseDefend: sections.veryMiddle.baseDefend, visible: sections.veryMiddle.visible},
    { id: 84, col: 11, row: 5, kef: sections.middle.kef, img:sections.middle.img, baseDefend: sections.middle.baseDefend, visible: sections.middle.visible   },
   
  
  ],
  kef: 11,
  type: 1,
  },
  {
    name: "column12",
    sections: [
      { id: 85, col: 12, row: 1, kef: sections.veryHard.kef, img:sections.veryHard.img,  baseDefend: sections.veryHard.baseDefend, visible: sections.veryHard.visible },
      { id: 86, col: 12, row: 2, kef: sections.hard.kef, img:sections.hard.img,baseDefend: sections.hard.baseDefend, visible: sections.hard.visible},
      { id: 87, col: 12, row: 3, kef:  sections.middle.kef, img:sections.middle.img,  baseDefend: sections.middle.baseDefend, visible: sections.middle.visible },
      { id: 88, col: 12, row: 4, kef: sections.veryHard.kef, img:sections.veryHard.img,  baseDefend: sections.veryHard.baseDefend, visible: sections.veryHard.visible},
      { id: 89, col: 12, row: 5, kef: sections.boloto.kef, img:sections.boloto.img, baseDefend: sections.boloto.baseDefend, visible: sections.boloto.visible   },
     
    
    ],
    kef: 12,
    type:2,
    },
    {
      name: "column13",
      sections: [
        { id: 90, col: 13, row: 1, kef: sections.middle.kef, img:sections.middle.img,  baseDefend: sections.middle.baseDefend, visible: sections.middle.visible  },
        { id: 91, col: 13, row: 2, kef: sections.middle.kef, img:sections.middle.img, baseDefend: sections.middle.baseDefend, visible: sections.middle.visible   },
        { id: 92, col: 13, row: 3,  kef: sections.middle.kef, img:sections.middle.img, baseDefend: sections.middle.baseDefend, visible: sections.middle.visible  },
        { id: 93, col: 13, row: 4, kef: sections.middle.kef, img:sections.middle.img, baseDefend: sections.middle.baseDefend, visible: sections.middle.visible   },
        { id: 94, col: 13, row: 5, kef: sections.middle.kef, img:sections.middle.img, baseDefend: sections.middle.baseDefend, visible: sections.middle.visible   },
       
      
      ],
      kef: 13,
      type: 1,
      },
      {
        name: "column14",
        sections: [
          { id: 95, col: 14, row: 1, kef: sections.middle.kef, img:sections.middle.img,  baseDefend: sections.middle.baseDefend, visible: sections.middle.visible  },
          { id: 96, col: 14, row: 2, kef: sections.middle.kef, img:sections.middle.img, baseDefend: sections.middle.baseDefend, visible: sections.middle.visible   },
          { id: 97, col: 14, row: 3, kef: sections.veryMiddle.kef, img:sections.veryMiddle.img, baseDefend: sections.veryMiddle.baseDefend, visible: sections.veryMiddle.visible },
          { id: 98, col: 14, row: 4, kef: sections.middle.kef, img:sections.middle.img, baseDefend: sections.middle.baseDefend, visible: sections.middle.visible   },
          { id: 99, col: 14, row: 5, kef: sections.middle.kef, img:sections.middle.img, baseDefend: sections.middle.baseDefend, visible: sections.middle.visible   },
         
        
        ],
        kef: 14,
        type:2,
        },
        {
          name: "column15",
          sections: [
            { id: 100, col: 15, row: 1, kef: sections.middle.kef, img:sections.middle.img,  baseDefend: sections.middle.baseDefend, visible: sections.middle.visible  },
            { id: 101, col: 15, row: 2, kef: sections.middle.kef, img:sections.middle.img,  baseDefend: sections.middle.baseDefend, visible: sections.middle.visible  },
            { id: 102, col: 15, row: 3, kef: sections.boloto.kef, img:sections.boloto.img, baseDefend: sections.boloto.baseDefend, visible: sections.boloto.visible   },
            { id: 103, col: 15, row: 4,  kef: sections.veryMiddle.kef, img:sections.veryMiddle.img, baseDefend: sections.veryMiddle.baseDefend, visible: sections.veryMiddle.visible},
            { id: 104, col: 15, row: 5, kef: sections.boloto.kef, img:sections.boloto.img, baseDefend: sections.boloto.baseDefend, visible: sections.boloto.visible   },
           
          
          ],
          kef: 15,
          type: 1,
          },
          {
            name: "column16",
            sections: [
              { id: 105, col: 16, row: 1, kef: sections.light5.kef, img:sections.light5.img, baseDefend: sections.light5.baseDefend, visible: sections.light5.visible   },
              { id: 106, col: 16, row: 2, kef: sections.boloto.kef, img:sections.boloto.img, baseDefend: sections.boloto.baseDefend, visible: sections.boloto.visible   },
              { id: 107, col: 16, row: 3, kef: sections.hard.kef, img:sections.hard.img,baseDefend: sections.hard.baseDefend, visible: sections.hard.visible},
              { id: 108, col: 16, row: 4, kef: sections.light7.kef, img:sections.light7.img, baseDefend: sections.light7.baseDefend, visible: sections.light7.visible   },
              { id: 109, col: 16, row: 5,  kef: sections.light.kef, img:sections.light.img, baseDefend: sections.light.baseDefend, visible: sections.light.visible    },
             
            
            ],
            kef: 16,
            type:2,
            },
            {
              name: "column17",
              sections: [
                { id: 110, col: 17, row: 1, kef: sections.middle.kef, img:sections.middle.img,  baseDefend: sections.middle.baseDefend, visible: sections.middle.visible  },
                { id: 111, col: 17, row: 2, kef: sections.light3.kef, img:sections.light3.img, baseDefend: sections.light3.baseDefend, visible: sections.light3.visible   },
                { id: 112, col: 17, row: 3, kef: sections.boloto.kef, img:sections.boloto.img, baseDefend: sections.boloto.baseDefend, visible: sections.boloto.visible  },
                { id: 113, col: 17, row: 4, kef: sections.light4.kef, img:sections.light4.img, baseDefend: sections.light4.baseDefend, visible: sections.light4.visible   },
                { id: 114, col: 17, row: 5, kef: sections.middle.kef, img:sections.middle.img, baseDefend: sections.middle.baseDefend, visible: sections.middle.visible   },
               
              
              ],
              kef: 17,
              type: 1,
              },
              {
                name: "column18",
                sections: [
                  { id: 115, col: 18, row: 1, kef: sections.hard.kef, img:sections.hard.img,baseDefend: sections.hard.baseDefend, visible: sections.hard.visible},
                  { id: 116, col: 18, row: 2, kef: sections.light12.kef, img:sections.light12.img, baseDefend: sections.light12.baseDefend, visible: sections.light12.visible   },
                  { id: 117, col: 18, row: 3,kef: sections.light6.kef, img:sections.light6.img, baseDefend: sections.light6.baseDefend, visible: sections.light6.visible   },
                  { id: 118, col: 18, row: 4, kef: sections.middle.kef, img:sections.middle.img, baseDefend: sections.middle.baseDefend, visible: sections.middle.visible    },
                  { id: 119, col: 18, row: 5, kef: sections.middle.kef, img:sections.middle.img, baseDefend: sections.middle.baseDefend, visible: sections.middle.visible   },
                 
                
                ],
                kef: 18,
                type:2,
                },
                {
                  name: "column19",
                  sections: [
                    { id: 120, col: 19, row: 1,kef:  sections.middle.kef, img:sections.middle.img,  baseDefend: sections.middle.baseDefend, visible: sections.middle.visible },
                    { id: 121, col: 19, row: 2,kef:  sections.middle.kef, img:sections.middle.img,  baseDefend: sections.middle.baseDefend, visible: sections.middle.visible},
                    { id: 122, col: 19, row: 3,  kef: sections.light3.kef, img: sections.light3.img, baseDefend: sections.light3.baseDefend, visible: sections.light3.visible},
                    { id: 123, col: 19, row: 4, kef:  sections.middle.kef, img:sections.middle.img,  baseDefend: sections.middle.baseDefend, visible: sections.middle.visible },
                    { id: 124, col: 19, row: 5, kef:  sections.middle.kef, img:sections.middle.img,  baseDefend: sections.middle.baseDefend, visible: sections.middle.visible },
                
                   
                  
                  ],
                  kef: 19,
                  type:1,
                  },
                  
 
]

const unitsPar = {
countUnits: {
  Inf5: 5,
  Inf4: 4,
  Art:3,
  Tanks: 3,
  Air2: 2,
  Air4:4,
  Inf2:2,
  Tech:6,
  ArmTech5:5,
  ArmTech3:3,
},
nameUnits: {
  T26: "T-26",
  Pz2c: "Pz-2C",
  Pz3g: "Pz-3G",
  Bt7: "Бт-7",
  InfSov: "Сов.пехота",
  InfWer: "Нем.пехота",
  Maxim: "Пулемёт Максим",
  Mg34: "Пулемёт MG-34",
  Pt45Sov: "",
  SaperWer: "Штурмпионеры",
  Zis5: "Грузовик Зис-5",
  OpelBlitz: "Грузовик OpelBlitz",
  T34: "T-34 обр.1940",



},
typeUnits: {
  light:"light",
  middle: "middle",
  heavy:"heavy",
  art:"artillery",
  humans:"humans",
  lightAir:"lightAir",
  heavyAir:"heavyAir",
  technics:"technics",
  armorTechnics:"armorTechnics",
  
},
intensity: {

  T26: {
      light: [7,6,5],
          middle: [5,4,3],
          humans: [9,7,5],
          technics: [10,9,8],
          artillery: [9,8,7],
          armorTechnics: [8,7,6] 
        },
  Pz2c: { light: [7,6,5],
          middle: [4, 3, 2],
          humans: [11, 10, 9],
          technics: [12,11,10],
          artillery: [11,10,9],
          armorTechnics: [8,7,6]
         },
  Pz3g: { light: [9, 8, 7],
          middle: [6, 5, 4],
          humans: [10,8,6],
          technics: [12,11,10],
          artillery: [11,9,7], 
          armorTechnics: [11,10,9]
        },
 // Bt7: "",
  InfSov: { light: [5, 4, 3,2,1],
            middle: [4,3,2,1,1],
            humans: [8, 6, 4,3,2],
            technics: [7,5,4,3,2],
            artillery: [8,6,4,2,1],
            armorTechnics: [6,4,3,2,1],
          },
  InfWer: { light: [5, 4, 3,2,1],
            middle: [4,3,2,1,1],
            humans: [9, 7, 5,3,2],
            technics: [8,6,4,3,2],
            artillery: [9,7,4,2,1],
            armorTechnics: [6,4,3,2,1]
          },
  Maxim: {light: [2,1],
          middle: [1,1],
          humans: [10, 6],
          technics: [9,5],
          artillery: [10,5],
          armorTechnics: [2,1]
        },
  Mg34: { light: [2,1],
           middle: [1,1],
          humans: [9, 5] ,
          technics: [8,4],
           artillery: [9,4],
             armorTechnics: [2,1],
            lightAir:[3,2] },
  Pt45Sov: { light: [7, 6, 5],
            middle: [5, 4, 3],
            humans: [10, 8, 6] ,
            technics: [10,9,8],
             artillery: [9,8,7],
            armorTechnics: [8,7,6] },
  SaperWer: { light: [7,5,4,3],
            middle: [6,5,4,2],
            humans: [6, 5, 3,2] ,
             heavy: [5,4,3,2] , 
            technics: [5,4,3,1], 
            artillery: [6,5,3,1],
            armorTechnics: [7,6,4,3],
            lightAir:[2,2,1,1] },
  Zis5: {   light: [5, 6, 7],
            middle: [3, 4, 5], 
             humans: [5, 7, 9],
            technics: [10,9,8],
           artillery: [9,8,7], 
            armorTechnics: [8,7,6] },
  OpelBlitz: { light: [5, 6, 7],
          middle: [3, 4, 5],
          humans: [5, 7, 9],
          technics: [10,9,8],
           artillery: [9,8,7],
           armorTechnics: [8,7,6] },
  T34: { light: [10, 9, 8],
         middle: [9, 8, 7],
        humans: [10, 8, 6] ,
        technics: [14,13,12],
        artillery: [12,10,8],
        armorTechnics: [13,12,11] },


},  precision: {
              T26: [2,2,2,1,1],
              Pz2c: [3,2,2,1],
              Pz3g: [2,2,2,1,1],
              // Bt7: "",
              InfSov: [2,2,1,1],
              InfWer: [2,2,1,1],
              Maxim: [3,3,2,1],
              Mg34: [3,3,2,1],
              Pt45Sov:[2,2,2,1,1],
              SaperWer: [3,2,1,1],
              Zis5: [0],
              OpelBlitz: [0],
              T34: [2,2,2,1,1],

}}



export const mockActions = {
  move: "move",
  fire: "fire",
  fire_hard: "fire_hard",
  defend: "defend",
  fire_move: "fire_move",
  ambush: "ambush",
  attack: "attack"
};

export const tanks = [
  {
    id: 1,
    name: "pz2-c",
    img: pz,
    type: "light",
    count: 3,
    actions: {
      move: 3,
      defend: 2,
      fire: {
        range: 3,
        ammunition: 10,
        precision: [3, 2, 1],
        intensity: { light: [5, 6, 7], middle: [4, 3, 2], humans: [9, 10, 11] },
      },
    },
  },
  {
    id: 2,
    name: "pz3-g",
    img: pz3g,
    type: "middle",
    count: 3,
    actions: {
      move: 3,
      defend: 2,
      fire: {
        range: 4,
        ammunition: 10,
        precision: [2, 2, 1, 1],
        intensity: { light: [7, 8, 9], middle: [4, 5, 6], humans: [6, 8, 10] },
      },
    },
  },
  {
    id: 3,
    name: "t26",
    img: t26_USSR,
    type: "light",
    count: 3,
    actions: {
      move: 3,
      defend: 1,
      fire: {
        range: 4,
        ammunition: 10,
        precision: [2, 2, 1, 1],
        intensity: { light: [5, 6, 7], middle: [3, 4, 5], humans: [5, 7, 9] },
      },
    },
  },
  {
    id: 4,
    name: "t34",
    img: t34,
    type: "middle",
    count: 3,
    actions: {
      move: 3,
      defend: 2,
      fire: {
        range: 4,
        ammunition: 10,
        precision: [2, 2, 1, 1],
        intensity: {
          light: [9, 10, 11],
          middle: [8, 9, 10],
          humans: [7, 9, 11],
        },
      },
    },
  },
];

export const mockTanks = [
 {
    id: 1,
    name: tanks[1].name,
    img: pz3g,
    team: 2,
    activeTank: true, //для дисэйбла огня
    count: 3,
    type: tanks[1].type,
    prevDeffStep: 0,
    afterStepData: { move: 3, defend: 2 },
    coordinates: {
      row: 1,
      col: 1,
      top: 0,
      left: 0,
      
      type: 1,
      id:8,
      
    }, 
    actions: {
      range: 4,
      move: 3,
      defend: 2,
      fire: {
        ammunition: 10,
        precision: unitsPar.precision.Pz3g,
        intensity: { light: [9, 8, 7], middle: [6, 5, 4], humans: [10,8,6]  , technics: [12,11,10], artillery: [11,9,7], armorTechnics: [11,10,9]},
      },
    },
    trajectory: [],
    visibible: [],
    defend_sector: [],
    defend_side: {} ,
    tank_attack: "none",
    order: "none",
    order1: "none",
    tank_attack1: "none",
    tank_attack_number:0,
    tank_active_attack1:[],
    tank_attack_bliz:'none',
    tank_new_coor:  {
      row: 6,
      col: 6,
      top: 0,
      left: 0,
      
      type: 2,
      id:8,
      
     
    }, 
    tanks_attack: [],
    morale: 9,
    tank_count_base: 3,
    fire_sup:false,
    tank_fear:false,
    side:undefined,
    side_rear_flanks: undefined
  },
  { 
    id: 2,   // norm
    name: tanks[1].name,
    img: tanks[1].img,
    team: 2,
    activeTank: true,
    count: 3,
    type: tanks[1].type,
    prevDeffStep: 0,
    afterStepData: { move: 6, defend: 2 },
    coordinates: {
      row: 5,
      col: 6,
      top: 0,
      left: 0,
      
      type: 1,
      id: 0,
      
    },
    actions: {
      range: 4,
      move: 6,
      defend: 2,
      fire: {
        
        ammunition: 10,
        precision: unitsPar.precision.Pz3g,
        intensity: { light: [9, 8, 7], middle: [6, 5, 4], humans: [10,8,6]  , technics: [12,11,10], artillery: [11,9,7], armorTechnics: [11,10,9]},
      },
    },
    trajectory: [],
    visibible: [],
    defend_sector: [],
    defend_side: {} ,
    tank_attack: "none",
    order: "none",
    order1: "none",
    tank_attack1: "none",
    tank_attack_number:0,
    tank_active_attack1:[],
    tank_attack_bliz:'none',
    tank_new_coor:  {
      row: 5,
      col: 6,
      top: 0,
      left: 0,
      
      type: 2,
      id: 0,
      
     
    }, 
    tanks_attack: [],
    morale: 9,
    tank_count_base: 3,
    fire_sup:false,
    tank_fear:false,
    side:undefined,
    side_rear_flanks: undefined
  },  {
    id: 3,
    name: tanks[0].name,
    img: tanks[0].img,
    team: 2,
    activeTank: true,
    count: 3,
    type: tanks[0].type,
    prevDeffStep: 0,
    afterStepData: { move: 3, defend: 2 },
    coordinates: {
      row: 7,
      col: 2,
      top: 0,
      left: 0,
      
      type: 2,
      id: 0,
      
    },
    actions: {
      range: 3,
      move: 3,
      defend: 2,
      fire: {
        ammunition: 10,
        precision: unitsPar.precision.Pz2c,
        intensity: { light: [7,6,5], middle: [4, 3, 2], humans: [11, 10, 9]  , technics: [12,11,10], artillery: [11,10,9], armorTechnics: [8,7,6] },
      },
    },
    trajectory: [],
    visibible: [],
    defend_sector: [],
    defend_side: {} ,
    tank_attack: "none",
    order: "none",
    order1: "none",
    tank_attack1: "none",
    tank_attack_number:0,
    tank_active_attack1:[],
    tank_attack_bliz:'none',
    tank_new_coor:  {
      row: 7,
      col: 2,
      top: 0,
      left: 0,
      
      type: 2,
      id: 0,
     
    }, 
    tanks_attack: [],
    morale: 9,
    tank_count_base: 3,
    fire_sup:false,
    tank_fear:false,
    side:undefined,
    side_rear_flanks: undefined
  },
   { 
    id:4,
    name: tanks[0].name,
    img: tanks[0].img,
    team: 2,
    activeTank: true,
    count: 3,
    type: tanks[0].type,
    prevDeffStep: 0,
    afterStepData: { move: 3, defend: 2 },
    coordinates: {
      row: 7,
      col: 2,
      top: 0,
      left: 0,
      
      type: 2,
      id:3,
      
    },
    actions: {
      range: 3,
      move: 3,
      defend: 2,
      fire: {
        ammunition: 10,
        precision: unitsPar.precision.Pz2c,
        intensity: { light: [7,6,5], middle: [4, 3, 2], humans: [11, 10, 9]  , technics: [12,11,10], artillery: [11,10,9], armorTechnics: [8,7,6] },
      },
    },
    trajectory: [],
    visibible: [],
    defend_sector: [],
    defend_side: {} ,
    tank_attack: "none",
    order: "none",
    order1: "none",
    tank_attack1: "none",
    tank_attack_number:0,
    tank_active_attack1:[],
    tank_attack_bliz:'none',
    tank_new_coor:  {
      row: 7,
      col: 2,
      top: 0,
      left: 0,
      
      type: 2,
      id:3,
     
    }, 
    tanks_attack: [],
    morale: 9,
    tank_count_base: 3,
    fire_sup:false,
    tank_fear:false,
    side:undefined,
    side_rear_flanks: undefined
  },
   {
   id: 5,
    name: tanks[2].name,
    img: tanks[2].img,
    team: 1,
    activeTank: true,
    count: tanks[2].count,
    type: tanks[2].type,
    prevDeffStep: 0,
    afterStepData: { move: 3, defend: 1 },
    coordinates: {
      row: 3,
      col: 6,
      top: 0,
      left: 0,
      
      type: 2,
      
    },
    actions: {
      range: 4,
      move: 3,
      defend: 1,
      fire: {
        ammunition: 10,
        precision:unitsPar.precision.T26,
        intensity:  { light: [7,6,5], middle: [5,4,3], humans: [9,7,5] , technics: [10,9,8], artillery: [9,8,7], armorTechnics: [8,7,6] },
      },
    },
    trajectory: [],
    visibible: [],
    defend_sector: [],
    defend_side: {} ,
    tank_attack: "none",
    order: "none",
    order1: "none",
    tank_attack1: "none",
    tank_attack_number:0,
    tank_active_attack1:[],
    tank_attack_bliz:'none',
    tank_new_coor:  {
      row: 3,
      col: 6,
      top: 0,
      left: 0,
      
      type: 2,
     
    }, 
    tanks_attack: [],
    morale: 9,
    tank_count_base: 3,
    fire_sup:false,
    tank_fear:false,
    side:undefined,
    side_rear_flanks: undefined
 }, 
  {
    id: 6,
    name: tanks[2].name,
    img: tanks[2].img,
    team: 1,
    activeTank: true,
    count: tanks[2].count,
    type: tanks[2].type,
    prevDeffStep: 0,
    afterStepData: { move: 3, defend: 1 },
    coordinates: {
      row: 5,
      col: 11,
      top: 0,
      left: 0,
      
      type: 2,
     
    },
    actions: {
      range: 4,
      move: 3,
      defend: 1,
      fire: {
        ammunition: 10,
        precision:unitsPar.precision.T26,
        intensity:  { light: [7,6,5], middle: [5,4,3], humans: [9,7,5] , technics: [10,9,8], artillery: [9,8,7], armorTechnics: [8,7,6] },
      },
    },
    trajectory: [],
    visibible: [],
    defend_sector: [],
    defend_side: {} ,
    tank_attack: "none",
    order: "none",
    order1: "none",
    tank_attack1: "none",
    tank_attack_number:0,
    tank_active_attack1:[],
    tank_attack_bliz:'none',
    tank_new_coor:  {
      row: 5,
      col: 11,
      top: 0,
      left: 0,
      
      type: 2,
     
    }, 
    tanks_attack: [],
    morale: 9,
    tank_count_base: 3,
    fire_sup:false,
    tank_fear:false,
    side:undefined,
    side_rear_flanks: undefined
  },
  {
    id: 7,
    name: tanks[3].name,
    img: tanks[3].img,
    team: 1,
    activeTank: true,
    count: tanks[3].count,
    type: tanks[3].type,
    prevDeffStep: 0,
    afterStepData: { move:10, defend: 3 },
    coordinates: {
      row: 3,
      col: 5,
      top: 0,
      left: 0,
      
      type: 1,
      
    },
    actions: {
      range: 4,
      move: 10,
      defend: 3,
      fire: {
        ammunition: 10,
        precision: unitsPar.precision.T34,
        intensity: { light: [10, 9, 8], middle: [9, 8, 7], humans: [10, 8, 6] , technics: [14,13,12], artillery: [12,10,8], armorTechnics: [13,12,11] },
      },
    },
    trajectory: [],
    visibible: [],
    defend_sector: [],
    defend_side: {} ,
    tank_attack: "none",
    order: "none",
    order1: "none",
    tank_attack1: "none",
    tank_attack_number:0,
    tank_active_attack1:[],
    tank_attack_bliz:'none',
    tank_new_coor:  {
      row: 3,
      col: 5,
      top: 0,
      left: 0,
      
      type: 1,
     
    }, 
    tanks_attack: [],
    morale: 9,
    tank_count_base: 3,
    fire_sup:false,
    tank_fear:false,
    side:undefined,
    side_rear_flanks: undefined
  },
  { 
    id: 8,
    name: tanks[2].name,
    img: tanks[2].img,
    team: 1,
    activeTank: true,
    count: tanks[2].count,
    type: tanks[2].type,
    prevDeffStep: 0,
    afterStepData: { move: 3, defend: 1 },
    coordinates: {
      row: 5,
      col: 11,
      top: 0,
      left: 0,
      
      type: 2,
     
    },
    actions: {
      range: 4,
      move: 3,
      defend: 1,
      fire: {
        ammunition: 10,
        precision: unitsPar.precision.T26,
        intensity:  { light: [7,6,5], middle: [5,4,3], humans: [9,7,5] , technics: [10,9,8], artillery: [9,8,7], armorTechnics: [8,7,6] },
      },
    },
    trajectory: [],
    visibible: [],
    defend_sector: [],
    defend_side: {} ,
    tank_attack: "none",
    order: "none",
    order1: "none",
    tank_attack1: "none",
    tank_attack_number:0,
    tank_active_attack1:[],
    tank_attack_bliz:'none',
    tank_new_coor:  {
      row: 5,
      col: 11,
      top: 0,
      left: 0,
      
      type: 2,
     
    }, 
    tanks_attack: [],
    morale: 9,
    tank_count_base: 3,
    fire_sup:false,
    tank_fear:false,
    side:undefined,
    side_rear_flanks: undefined
  },
  {
    id: 9,
    name: tanks[2].name,
    img: tanks[2].img,
    team: 1,
    activeTank: true,
    count: tanks[2].count,
    type: tanks[2].type,
    prevDeffStep: 0,
    afterStepData: { move: 3, defend: 1 },
    coordinates: {
      row: 8,
      col: 11,
      top: 0,
      left: 0,
      
      type: 2,
     
    },
    actions: {
      range: 4,
      move: 3,
      defend: 1,
      fire: {
        ammunition: 10,
        precision:unitsPar.precision.T26,
        intensity:  { light: [7,6,5], middle: [5,4,3], humans: [9,7,5] , technics: [10,9,8], artillery: [9,8,7], armorTechnics: [8,7,6] },
      },
    },
    trajectory: [],
    visibible: [],
    defend_sector: [],
    defend_side: {} ,
    tank_attack: "none",
    order: "none",
    order1: "none",
    tank_attack1: "none",
    tank_attack_number:0,
    tank_active_attack1:[],
    tank_attack_bliz:'none',
    tank_new_coor:  {
      row: 8,
      col: 11,
      top: 0,
      left: 0,
      
      type: 2,
     
    }, 
    tanks_attack: [],
    morale: 9,
    tank_count_base: 3,
    fire_sup:false,
    tank_fear:false,
    side:undefined,
    side_rear_flanks: undefined
    
    
  },  
];

export const mockTanks1 = [
  {
    id: 1,
    name: "pz3g",
    img: pz3g,
    team: 2,
    activeTank: true, //для дисэйбла огня
    count: 3,
    type: "middle",
    prevDeffStep: 0,
    afterStepData: { move: 3, defend: 2 },
    coordinates: {
      row: 1,
      col: 16,
      top: 0,
      left: 0,
      
      type: 2,
      id:8,
      
    }, 
    actions: {
      range: 4,
      move: 3,
      defend: 2,
      fire: {
        ammunition: 10,
        precision: unitsPar.precision.Pz3g,
        intensity: { light: [9, 8, 7], middle: [6, 5, 4], humans: [10,8,6]  , technics: [12,11,10], artillery: [11,9,7], armorTechnics: [11,10,9]},
      },
    },
    trajectory: [],
    visibible: [],
    defend_sector: [],
    defend_side: {} ,
    tank_attack: "none",
    order: "none",
    order1: "none",
    tank_attack1: "none",
    tank_attack_number:0,
    tank_active_attack1:[],
    tank_attack_bliz:'none',
    tank_new_coor:  {
      row: 1,
      col: 16,
      top: 0,
      left: 0,
      
      type: 2,
      id:8,
      
     
    }, 
    tanks_attack: [],
    morale: 9,
    tank_count_base: 3,
    fire_sup:false,
    tank_fear:false,
    side:undefined,
    side_rear_flanks: undefined
  },
  { 
    id: 2,   // norm
    name: "pz2c",
    img: pz,
    team: 2,
    activeTank: true,
    count: 3,
    type: "light",
    prevDeffStep: 0,
    afterStepData: { move: 3, defend: 2 },
    coordinates: {
      row: 2,
      col: 16,
      top: 0,
      left: 0,
      
      type: 1,
      id: 0,
      
    },
    actions: {
      range: 3,
      move: 3,
      defend: 2,
      fire: {
        
        ammunition: 10,
        precision: unitsPar.precision.Pz2c,
        intensity: { light: [7,6,5], middle: [4, 3, 2], humans: [11, 10, 9]  , technics: [12,11,10], artillery: [11,10,9], armorTechnics: [8,7,6] },
      },
    },
    trajectory: [],
    visibible: [],
    defend_sector: [],
    defend_side: {} ,
    tank_attack: "none",
    order: "none",
    order1: "none",
    tank_attack1: "none",
    tank_attack_number:0,
    tank_active_attack1:[],
    tank_attack_bliz:'none',
    tank_new_coor:  {
      row: 2,
      col: 16,
      top: 0,
      left: 0,
      
      type: 1,
      id: 0,
      
     
    }, 
    tanks_attack: [],
    morale: 9,
    tank_count_base: 3,
    fire_sup:false,
    tank_fear:false,
    side:undefined,
    side_rear_flanks: undefined
  },  {
    id: 3,
    name: "infantry",
    img: pehWer,
    team: 2,
    activeTank: true,
    count: 5,
    type: "humans",
    prevDeffStep: 0,
    afterStepData: { move: 3, defend: 1 },
    coordinates: {
      row: 4,
      col: 4,
      top: 0,
      left: 0,
      
      type: 2,
      id: 0,
      
    },
    actions: {
      range: 3,
      move: 3,
      defend: 1,
      fire: {
        ammunition: 10,
        precision: unitsPar.precision.InfWer,
        intensity: { light: [5, 4, 3,2,1], middle: [4,3,2,1,1], humans: [9, 7, 5,3,2] , technics: [8,6,4,3,2], artillery: [9,7,4,2,1], armorTechnics: [6,4,3,2,1] },
      },
    },
    trajectory: [],
    visibible: [],
    defend_sector: [],
    defend_side: {} ,
    tank_attack: "none",
    order: "none",
    order1: "none",
    tank_attack1: "none",
    tank_attack_number:0,
    tank_active_attack1:[],
    tank_attack_bliz:'none',
    tank_new_coor:  {
      row: 4,
      col: 4,
      top: 0,
      left: 0,
      
      type: 2,
      id: 0,
     
    }, 
    tanks_attack: [],
    morale: 8,
    tank_count_base: 5,
    fire_sup:false,
    tank_fear:false,
    side:undefined,
    side_rear_flanks: undefined
  },
   { 
    id:4,
    name: "stormtrooper",
    img: saperWer,
    team: 2,
    activeTank: true,
    count: 4,
    type: "humans",
    prevDeffStep: 0,
    afterStepData: { move: 3, defend: 1 },
    coordinates: {
      row: 2,
      col: 4,
      top: 0,
      left: 0,
      
      type: 2,
      id:3,
      
    },
    actions: {
      range: 3,
      move: 3,
      defend: 1,
      fire: {
        ammunition: 10,
        precision: unitsPar.precision.SaperWer,
        intensity: { light: [7,5,4,3], middle: [6,5,4,2],  humans: [6, 5, 3,2] , heavy: [5,4,3,2] , technics: [5,4,3,1], artillery: [6,5,3,1], armorTechnics: [7,6,4,3],lightAir:[2,2,1,1] },
      },
    },
    trajectory: [],
    visibible: [],
    defend_sector: [],
    defend_side: {} ,
    tank_attack: "none",
    order: "none",
    order1: "none",
    tank_attack1: "none",
    tank_attack_number:0,
    tank_active_attack1:[],
    tank_attack_bliz:'none',
    tank_new_coor:  {
      row: 2,
      col: 4,
      top: 0,
      left: 0,
      
      type: 2,
      id:3,
     
    }, 
    tanks_attack: [],
    morale: 8,
    tank_count_base: 4,
    fire_sup:false,
    tank_fear:false,
    side:undefined,
    side_rear_flanks: undefined
  },
   {
   id: 5,
    name: "mg34",
    img: mg34,
    team: 2,
    activeTank: true,
    count: 2,
    type: "humans",
    prevDeffStep: 0,
    afterStepData: { move: 3, defend: 1 },
    coordinates: {
      row: 3,
      col: 4,
      top: 0,
      left: 0,
      
      type: 2,
      
    },
    actions: {
      range: 3,
      move: 3,
      defend: 1,
      fire: {
        ammunition: 10,
        precision:unitsPar.precision.Mg34,
        intensity:  { light: [2,1], middle: [1,1], humans: [9, 5] , technics: [8,4], artillery: [9,4], armorTechnics: [2,1],lightAir:[3,2] },
      },
    },
    trajectory: [],
    visibible: [],
    defend_sector: [],
    defend_side: {} ,
    tank_attack: "none",
    order: "none",
    order1: "none",
    tank_attack1: "none",
    tank_attack_number:0,
    tank_active_attack1:[],
    tank_attack_bliz:'none',
    tank_new_coor:  {
      row: 3,
      col: 4,
      top: 0,
      left: 0,
      
      type: 2,
     
    }, 
    tanks_attack: [],
    morale: 8,
    tank_count_base: 2,
    fire_sup:false,
    tank_fear:false,
    side:undefined,
    side_rear_flanks: undefined
 }, 
  {
    id: 6,
    name: "infantry",
    img: pehSov,
    team: 1,
    activeTank: true,
    count: 5,
    type: "humans",
    prevDeffStep: 0,
    afterStepData: { move: 3, defend: 1 },
    coordinates: {
      row: 2,
      col: 14,
      top: 0,
      left: 0,
      
      type: 2,
     
    },
    actions: {
      range: 3,
      move: 3,
      defend: 1,
      fire: {
        ammunition: 10,
        precision:unitsPar.precision.InfSov,
        intensity:  { light: [5, 4, 3,2,1], middle: [4,3,2,1,1], humans: [8, 6, 4,3,2], technics: [7,5,4,3,2], artillery: [8,6,4,2,1], armorTechnics: [6,4,3,2,1],lightAir:[2,2,1,1,1] },
      },
    },
    trajectory: [],
    visibible: [],
    defend_sector: [],
    defend_side: {} ,
    tank_attack: "none",
    order: "none",
    order1: "none",
    tank_attack1: "none",
    tank_attack_number:0,
    tank_active_attack1:[],
    tank_attack_bliz:'none',
    tank_new_coor:  {
      row: 2,
      col: 14,
      top: 0,
      left: 0,
      
      type: 2,
     
    }, 
    tanks_attack: [],
    morale: 7,
    tank_count_base: 5,
    fire_sup:false,
    tank_fear:false,
    side:undefined,
    side_rear_flanks: undefined
  },
  {
    id: 7,
    name: "maxim",
    img: maxim,
    team: 1,
    activeTank: true,
    count: 2,
    type: "humans",
    prevDeffStep: 0,
    afterStepData: { move:3, defend: 1 },
    coordinates: {
      row: 4,
      col: 14,
      top: 0,
      left: 0,
      
      type: 1,
      
    },
    actions: {
      range: 3,
      move: 3,
      defend: 4,
      fire: {
        ammunition: 10,
        precision: unitsPar.precision.Maxim,
        intensity: { light: [2,1], middle: [1,1], humans: [10, 6] , technics: [9,5], artillery: [10,5], armorTechnics: [2,1]},
      },
    },
    trajectory: [],
    visibible: [],
    defend_sector: [],
    defend_side: {} ,
    tank_attack: "none",
    order: "none",
    order1: "none",
    tank_attack1: "none",
    tank_attack_number:0,
    tank_active_attack1:[],
    tank_attack_bliz:'none',
    tank_new_coor:  {
      row: 4,
      col: 14,
      top: 0,
      left: 0,
      
      type: 1,
     
    }, 
    tanks_attack: [],
    morale: 7,
    tank_count_base: 2,
    fire_sup:false,
    tank_fear:false,
    side:undefined,
    side_rear_flanks: undefined
  },
  { 
    id: 8,
    name: "zis5",
    img: zis5,
    team: 1,
    activeTank: true,
    count: 6,
    type: "technics",
    prevDeffStep: 0,
    afterStepData: { move: 4, defend: 0 },
    coordinates: {
      row: 1,
      col: 10,
      top: 0,
      left: 0,
      
      type: 2,
     
    },
    actions: {
      range: 5,
      move: 4,
      defend: 0,
      fire: {
        ammunition: 10,
        precision: unitsPar.precision.Zis5,
        intensity: { light: [5, 6, 7], middle: [3, 4, 5], humans: [5, 7, 9], technics: [10,9,8], artillery: [9,8,7], armorTechnics: [8,7,6] },
      },
    },
    trajectory: [],
    visibible: [],
    defend_sector: [],
    defend_side: {} ,
    tank_attack: "none",
    order: "none",
    order1: "none",
    tank_attack1: "none",
    tank_attack_number:0,
    tank_active_attack1:[],
    tank_attack_bliz:'none',
    tank_new_coor:  {
      row: 1,
      col: 10,
      top: 0,
      left: 0,
      
      type: 2,
     
    }, 
    tanks_attack: [],
    morale: 7,
    tank_count_base: 6,
    fire_sup:false,
    tank_fear:false,
    side:undefined,
    side_rear_flanks: undefined
  },
  {
    id: 9,
    name: "cannon45mm",
    img: ptSov45mm,
    team: 1,
    activeTank: true,
    count: 3,
    type: "artillery",
    prevDeffStep: 0,
    afterStepData: { move: 2, defend: 1 },
    coordinates: {
      row: 3,
      col: 10,
      top: 0,
      left: 0,
      
      type: 2,
     
    },
    actions: {
      range: 4,
      move: 2,
      defend: 1,
      fire: {
        ammunition: 10,
        precision:unitsPar.precision.Pt45Sov,
        intensity: { light: [7, 6, 5], middle: [5, 4, 3], humans: [10, 8, 6] , technics: [10,9,8], artillery: [9,8,7], armorTechnics: [8,7,6] },
      },
    },
    trajectory: [],
    visibible: [],
    defend_sector: [],
    defend_side: {} ,
    tank_attack: "none",
    order: "none",
    order1: "none",
    tank_attack1: "none",
    tank_attack_number:0,
    tank_active_attack1:[],
    tank_attack_bliz:'none',
    tank_new_coor:  {
      row: 3,
      col: 10,
      top: 0,
      left: 0,
      
      type: 2,
     
    }, 
    tanks_attack: [],
    morale: 7,
    tank_count_base: 3,
    fire_sup:false,
    tank_fear:false,
    side:undefined,
    side_rear_flanks: undefined
    
    
  },  
  {
   id: 10,
   name: "Сов.Пехота",
   img: t34,
   team: 1,
   activeTank: true,
   count: 3,
   type: "middle",
   prevDeffStep: 0,
   afterStepData: { move: 3, defend: 3 },
   coordinates: {
     row: 2,
     col: 12,
     top: 0,
     left: 0,
     
     type: 2,
    
   },
   actions: {
     range: 4,
     move: 3,
     defend: 3,
     fire: {
       ammunition: 10,
       precision:unitsPar.precision.T34,
       intensity: { light: [10, 9, 8], middle: [9, 8, 7], humans: [10, 8, 6] , technics: [14,13,12], artillery: [12,10,8], armorTechnics: [13,12,11] },
     },
   },
   trajectory: [],
   visibible: [],
   defend_sector: [],
   defend_side: {} ,
   tank_attack: "none",
   order: "none",
   order1: "none",
   tank_attack1: "none",
   tank_attack_number:0,
   tank_active_attack1:[],
   tank_attack_bliz:'none',
   tank_new_coor:  {
     row: 2,
     col: 12,
     top: 0,
     left: 0,
     
     type: 2,
    
   }, 
   tanks_attack: [],
   morale: 9,
   tank_count_base: 3,
   fire_sup:false,
   tank_fear:false,
   side:undefined,
   side_rear_flanks: undefined
   
   
 },  
 ];

 export const mockTanks2 = [
  {
     id: 1,
     name: "pz3g",
     img: pz3g,
     team: 2,
     activeTank: true, //для дисэйбла огня
     count: 3,
     type: "middle",
     prevDeffStep: 0,
     afterStepData: { move: 3, defend: 2 },
     coordinates: {
       row: 4,
       col: 4,
       top: 0,
       left: 0,
       
       type: 2,
       id:8,
       
     }, 
     actions: {
       range: 4,
       move: 3,
       defend: 2,
       fire: {
         ammunition: 10,
         precision: unitsPar.precision.Pz3g,
         intensity: { light: [9, 8, 7], middle: [6, 5, 4], humans: [10,8,6]  , technics: [12,11,10], artillery: [11,9,7], armorTechnics: [11,10,9]},
       },
     },
     trajectory: [],
     visibible: [],
     defend_sector: [],
     defend_side: {} ,
     tank_attack: "none",
     order: "none",
     order1: "none",
     tank_attack1: "none",
     tank_attack_number:0,
     tank_active_attack1:[],
     tank_attack_bliz:'none',
     tank_new_coor:  {
       row: 4,
       col: 4,
       top: 0,
       left: 0,
       
       type: 2,
       id:8,
       
      
     }, 
     tanks_attack: [],
     morale: 9,
     tank_count_base: 3,
     fire_sup:false,
     tank_fear:false,
     side:undefined,
     side_rear_flanks: undefined
   },
   { 
     id: 2,   // norm
     name: "pz2c",
     img: pz,
     team: 2,
     activeTank: true,
     count: 3,
     type: "light",
     prevDeffStep: 0,
     afterStepData: { move: 3, defend: 2 },
     coordinates: {
       row: 3,
       col: 4,
       top: 0,
       left: 0,
       
       type: 1,
       id: 0,
       
     },
     actions: {
       range: 3,
       move: 3,
       defend: 2,
       fire: {
         
         ammunition: 10,
         precision: unitsPar.precision.Pz2c,
         intensity: { light: [7,6,5], middle: [4, 3, 2], humans: [11, 10, 9]  , technics: [12,11,10], artillery: [11,10,9], armorTechnics: [8,7,6] },
       },
     },
     trajectory: [],
     visibible: [],
     defend_sector: [],
     defend_side: {} ,
     tank_attack: "none",
     order: "none",
     order1: "none",
     tank_attack1: "none",
     tank_attack_number:0,
     tank_active_attack1:[],
     tank_attack_bliz:'none',
     tank_new_coor:  {
       row: 3,
       col: 4,
       top: 0,
       left: 0,
       
       type: 1,
       id: 0,
       
      
     }, 
     tanks_attack: [],
     morale: 9,
     tank_count_base: 3,
     fire_sup:false,
     tank_fear:false,
     side:undefined,
     side_rear_flanks: undefined
   },  {
     id: 3,
     name: "infantry",
     img: pehWer,
     team: 2,
     activeTank: true,
     count: 5,
     type: "humans",
     prevDeffStep: 0,
     afterStepData: { move: 3, defend: 1 },
     coordinates: {
       row: 2,
       col: 5,
       top: 0,
       left: 0,
       
       type: 2,
       id: 0,
       
     },
     actions: {
       range: 3,
       move: 3,
       defend: 1,
       fire: {
         ammunition: 10,
         precision: unitsPar.precision.InfWer,
         intensity: { light: [5, 4, 3,2,1], middle: [4,3,2,1,1], humans: [9, 7, 5,3,2] , technics: [8,6,4,3,2], artillery: [9,7,4,2,1], armorTechnics: [6,4,3,2,1] },
       },
     },
     trajectory: [],
     visibible: [],
     defend_sector: [],
     defend_side: {} ,
     tank_attack: "none",
     order: "none",
     order1: "none",
     tank_attack1: "none",
     tank_attack_number:0,
     tank_active_attack1:[],
     tank_attack_bliz:'none',
     tank_new_coor:  {
       row: 2,
       col: 5,
       top: 0,
       left: 0,
       
       type: 2,
       id: 0,
      
     }, 
     tanks_attack: [],
     morale: 8,
     tank_count_base: 5,
     fire_sup:false,
     tank_fear:false,
     side:undefined,
     side_rear_flanks: undefined
   },
    { 
     id:4,
     name: "stormtrooper",
     img: saperWer,
     team: 2,
     activeTank: true,
     count: 4,
     type: "humans",
     prevDeffStep: 0,
     afterStepData: { move: 3, defend: 1 },
     coordinates: {
       row: 1,
       col: 4,
       top: 0,
       left: 0,
       
       type: 2,
       id:3,
       
     },
     actions: {
       range: 3,
       move: 3,
       defend: 1,
       fire: {
         ammunition: 10,
         precision: unitsPar.precision.SaperWer,
         intensity: { light: [7,5,4,3], middle: [6,5,4,2],  humans: [6, 5, 3,2] , heavy: [5,4,3,2] , technics: [5,4,3,1], artillery: [6,5,3,1], armorTechnics: [7,6,4,3],lightAir:[2,2,1,1] },
       },
     },
     trajectory: [],
     visibible: [],
     defend_sector: [],
     defend_side: {} ,
     tank_attack: "none",
     order: "none",
     order1: "none",
     tank_attack1: "none",
     tank_attack_number:0,
     tank_active_attack1:[],
     tank_attack_bliz:'none',
     tank_new_coor:  {
       row: 1,
       col: 4,
       top: 0,
       left: 0,
       
       type: 2,
       id:3,
      
     }, 
     tanks_attack: [],
     morale: 8,
     tank_count_base: 4,
     fire_sup:false,
     tank_fear:false,
     side:undefined,
     side_rear_flanks: undefined
   },
    {
    id: 5,
     name: "mg34",
     img: mg34,
     team: 2,
     activeTank: true,
     count: 2,
     type: "humans",
     prevDeffStep: 0,
     afterStepData: { move: 3, defend: 1 },
     coordinates: {
       row: 2,
       col: 4,
       top: 0,
       left: 0,
       
       type: 2,
       
     },
     actions: {
       range: 3,
       move: 3,
       defend: 1,
       fire: {
         ammunition: 10,
         precision:unitsPar.precision.Mg34,
         intensity:  { light: [2,1], middle: [1,1], humans: [9, 5] , technics: [8,4], artillery: [9,4], armorTechnics: [2,1],lightAir:[3,2] },
       },
     },
     trajectory: [],
     visibible: [],
     defend_sector: [],
     defend_side: {} ,
     tank_attack: "none",
     order: "none",
     order1: "none",
     tank_attack1: "none",
     tank_attack_number:0,
     tank_active_attack1:[],
     tank_attack_bliz:'none',
     tank_new_coor:  {
       row: 2,
       col: 4,
       top: 0,
       left: 0,
       
       type: 2,
      
     }, 
     tanks_attack: [],
     morale: 8,
     tank_count_base: 2,
     fire_sup:false,
     tank_fear:false,
     side:undefined,
     side_rear_flanks: undefined
  }, 
   {
     id: 6,
     name: "infantry",
     img: pehSov,
     team: 1,
     activeTank: true,
     count: 5,
     type: "humans",
     prevDeffStep: 0,
     afterStepData: { move: 3, defend: 1 },
     coordinates: {
       row: 4,
       col: 12,
       top: 0,
       left: 0,
       
       type: 2,
      
     },
     actions: {
       range: 3,
       move: 3,
       defend: 1,
       fire: {
         ammunition: 10,
         precision:unitsPar.precision.InfSov,
         intensity:  { light: [5, 4, 3,2,1], middle: [4,3,2,1,1], humans: [8, 6, 4,3,2], technics: [7,5,4,3,2], artillery: [8,6,4,2,1], armorTechnics: [6,4,3,2,1],lightAir:[2,2,1,1,1] },
       },
     },
     trajectory: [],
     visibible: [],
     defend_sector: [],
     defend_side: {} ,
     tank_attack: "none",
     order: "none",
     order1: "none",
     tank_attack1: "none",
     tank_attack_number:0,
     tank_active_attack1:[],
     tank_attack_bliz:'none',
     tank_new_coor:  {
       row: 4,
       col: 12,
       top: 0,
       left: 0,
       
       type: 2,
      
     }, 
     tanks_attack: [],
     morale: 7,
     tank_count_base: 5,
     fire_sup:false,
     tank_fear:false,
     side:undefined,
     side_rear_flanks: undefined
   },
   {
     id: 7,
     name: "dot",
     img: dot,
     team: 1,
     activeTank: true,
     count: 2,
     type: "humans",
     prevDeffStep: 0,
     afterStepData: { move:0.1, defend: 4 },
     coordinates: {
       row: 2,
       col: 14,
       top: 0,
       left: 0,
       
       type: 1,
       
     },
     actions: {
       range: 3,
       move: 0.1,
       defend: 4,
       fire: {
         ammunition: 15,
         precision: unitsPar.precision.Maxim,
         intensity: { light: [2,1], middle: [1,1], humans: [10, 6] , technics: [9,5], artillery: [10,5], armorTechnics: [2,1]},
       },
     },
     trajectory: [],
     visibible: [],
     defend_sector: [],
     defend_side: {} ,
     tank_attack: "none",
     order: "none",
     order1: "none",
     tank_attack1: "none",
     tank_attack_number:0,
     tank_active_attack1:[],
     tank_attack_bliz:'none',
     tank_new_coor:  {
       row: 2,
       col: 14,
       top: 0,
       left: 0,
       
       type: 1,
      
     }, 
     tanks_attack: [],
     morale: 8,
     tank_count_base: 2,
     fire_sup:false,
     tank_fear:false,
     side:undefined,
     side_rear_flanks: undefined
   },
   { 
     id: 8,
     name: "zis5",
     img: zis5,
     team: 1,
     activeTank: true,
     count: 6,
     type: "technics",
     prevDeffStep: 0,
     afterStepData: { move: 4, defend: 0 },
     coordinates: {
       row: 2,
       col: 17,
       top: 0,
       left: 0,
       
       type: 1,
      
     },
     actions: {
       range: 0,
       move: 4,
       defend: 0,
       fire: {
         ammunition: 10,
         precision: unitsPar.precision.Zis5,
         intensity: { light: [5, 6, 7], middle: [3, 4, 5], humans: [5, 7, 9], technics: [10,9,8], artillery: [9,8,7], armorTechnics: [8,7,6] },
       },
     },
     trajectory: [],
     visibible: [],
     defend_sector: [],
     defend_side: {} ,
     tank_attack: "none",
     order: "none",
     order1: "none",
     tank_attack1: "none",
     tank_attack_number:0,
     tank_active_attack1:[],
     tank_attack_bliz:'none',
     tank_new_coor:  {
       row: 2,
       col: 17,
       top: 0,
       left: 0,
       
       type: 1,
      
     }, 
     tanks_attack: [],
     morale: 7,
     tank_count_base: 6,
     fire_sup:false,
     tank_fear:false,
     side:undefined,
     side_rear_flanks: undefined
   },
   {
     id: 9,
     name: "cannon45mm",
     img: ptSov45mm,
     team: 1,
     activeTank: true,
     count: 3,
     type: "artillery",
     prevDeffStep: 0,
     afterStepData: { move: 2, defend: 1 },
     coordinates: {
       row: 1,
       col: 16,
       top: 0,
       left: 0,
       
       type: 2,
      
     },
     actions: {
       range: 4,
       move: 2,
       defend: 1,
       fire: {
         ammunition: 10,
         precision:unitsPar.precision.Pt45Sov,
         intensity: { light: [7, 6, 5], middle: [5, 4, 3], humans: [10, 8, 6] , technics: [10,9,8], artillery: [9,8,7], armorTechnics: [8,7,6] },
       },
     },
     trajectory: [],
     visibible: [],
     defend_sector: [],
     defend_side: {} ,
     tank_attack: "none",
     order: "none",
     order1: "none",
     tank_attack1: "none",
     tank_attack_number:0,
     tank_active_attack1:[],
     tank_attack_bliz:'none',
     tank_new_coor:  {
       row: 1,
       col: 16,
       top: 0,
       left: 0,
       
       type: 2,
      
     }, 
     tanks_attack: [],
     morale: 7,
     tank_count_base: 3,
     fire_sup:false,
     tank_fear:false,
     side:undefined,
     side_rear_flanks: undefined
     
     
   },  
   {
    id: 10,
    name: "t34",
    img: t34,
    team: 1,
    activeTank: true,
    count: 0,
    type: "middle",
    prevDeffStep: 0,
    afterStepData: { move: 3, defend: 3 },
    coordinates: {
      row: -1,
      col: -1,
      top: 0,
      left: 0,
      
      type: 2,
     
    },
    actions: {
      range: 4,
      move: 3,
      defend: 3,
      fire: {
        ammunition: 10,
        precision:unitsPar.precision.T34,
        intensity: { light: [10, 9, 8], middle: [9, 8, 7], humans: [10, 8, 6] , technics: [14,13,12], artillery: [12,10,8], armorTechnics: [13,12,11] },
      },
    },
    trajectory: [],
    visibible: [],
    defend_sector: [],
    defend_side: {} ,
    tank_attack: "none",
    order: "none",
    order1: "none",
    tank_attack1: "none",
    tank_attack_number:0,
    tank_active_attack1:[],
    tank_attack_bliz:'none',
    tank_new_coor:  {
      row: 2,
      col: 10,
      top: 0,
      left: 0,
      
      type: 2,
     
    }, 
    tanks_attack: [],
    morale: 9,
    tank_count_base: 3,
    fire_sup:false,
    tank_fear:false,
    side:undefined,
    side_rear_flanks: undefined,
    visibleSce:true
    
    
  },  
 ];

export const dataFieldAll = [{name:'Танковый бой',dataField4:dataField,col:dataField.length,row:dataField[0].sections.length},{name:"Окружение",dataField4:dataField1,col:dataField1.length,row:dataField1[0].sections.length},{name:"Вторжение",dataField4:dataField2,col:dataField2.length,row:dataField2[0].sections.length}]
export const mockTanksAll = [{name:'Танковый бой',mockTanks4:mockTanks},{name:"Окружение",mockTanks4:mockTanks1},{name:"Вторжение",mockTanks4:mockTanks2}]
console.log(dataField1.length,dataField1[0].sections.length)
// export const respawnTanksAfterMove = [

// ]
