import { FC, useEffect, useState } from "react";
// import { Board } from "../../models/Board";
// import BoardComponent from "../../components/BoardComponent";
import { Header } from "../../components/header/header";
import style from "./Server.module.css"
import { Field } from "../../gameComponents/Field/Field";
import { Link, redirect, useLocation, useNavigate } from "react-router-dom";
import { NavBar } from "../../components/navBar/navBar";
import { LobbyServer } from "./LobbyServer";
// export const PlayPage = () => {
//     const [board, setBoard] = useState(new Board())
    
//     useEffect(() => {
//         restart()

//     }, [])

//     function restart() {
//         const newBoard = new Board();
//         newBoard.initCells()
//         newBoard.addUnits()
//         setBoard(newBoard)
        
//     }



//     return (
//         <div className="app">
//    <Header />
//             <BoardComponent
//                 board={board}
//                 setBoard={setBoard}
                

             
//             />


//         </div>

//     );
// }

interface Ilobbycreate {
 player:string



}

export const LobbyCreate: FC<Ilobbycreate> = ({
  player

}) => {
  const location = useLocation()
  const [button,setButton] = useState<boolean>(false)
  const [cart,setCart] = useState<string>()
  const [name,setSueta] = useState<string>()
  const id = (+new Date).toString(16) + "hflshvbye83ksmv"
  console.log(location)
  const handbutton = () => {
   
     const socket = new WebSocket('ws://aot.artofvictory.ru:8080/ws')
   
    socket.onopen = () => {
       
    
       
     
       socket.send(JSON.stringify({

        names: location.state.login,
        cartLobby:cart,
        method: "createLobby",
        cartLobby1:name,
        id:id,
        id1:0,
        units1:[],
        units2:[],
        units3:false,
        hod:0,
        redirect_play:false ,
        players:[{ready:'Не готов', player:location.state.login, fraction: 0, id:id, ready_play:false,mocktanks:[]}]

       }))}
    



  }
 /* useEffect(() => {
    const socket = new WebSocket('ws://localhost:5000/')
   
socket.onopen = () => {


   
 
   socket.send(JSON.stringify({
    nameLobby:name,
    cartLobby:cart,

    

   }))}
  },[button]
   
  )*/
  

    
    console.log()
    console.log(name)
    console.log(location)
   
   location.state.create = true
    
    const navigate = useNavigate()
    
     return(
      <div style={{display:'flex' }}>
      <div className={style.lobbyDivCreate}>
      <h1 style={{fontSize: "25px", textAlign: "center",  padding: "1px"}}>Создания Лобби</h1>
      <p style={{textAlign:'center'}} > Название Лобби </p>
      <div  ><input onChange={(e) => setSueta(e.target.value) } className={style.LobbyCreateName} style={{display:'block',marginLeft:'auto',marginRight:'auto', padding:'3px',textAlign:'center',fontWeight:'bold'}} placeholder="Ведите название лобби..." maxLength={10} ></input></div>
      <p style={{textAlign:'center'}} > Карта </p>
      <select style={{display:'block',marginLeft:'auto',marginRight:'auto',textAlign:'center',fontWeight:'bold'}} className={style.selects} onChange={(e) => setCart(e.target.value) }>
      
      <option className={style.selects1} >Выбрать карту</option>
      <option className={style.selects1} value="Танковый бой">Танковый Бой</option>
      <option className={style.selects1} value="Вторжение">Вторжение</option>
      <option className={style.selects1} value="Окружение">Окружение</option>

      </select>
     
      <button onClick={( ) =>  {handbutton(); navigate(`/sueta111/lobby/${id}`,{state: location.state,replace:true}) }} className={style.lobbyButtonCreateLobby}>Cоздать</button>
      
      
      
      
      
      </div>
      
      </div>
      )


   
}
