import { useState, FC } from "react";
import style from "./actionMenu.module.css";

import { ITank } from "../../types/types";
import { dataField, dataFieldAll } from "../../mockData/mockData";
import { Location } from "react-router-dom";

interface IToolTipMenu1 {
tank:ITank
posX:number
posY:number
visible: any;
loc:Location

}
export const ToolTipMenu1: FC<IToolTipMenu1> = ({
  tank,
  posX,
  posY,
  visible= "collapse",
  loc
}) => {
  let baseDef: number | undefined
  
  
  const trueCell =  tank.count >=0  ?  0 : dataFieldAll.find((tank) => tank.name === loc.state.cart)!.dataField4[tank.coordinates.col-1].sections[tank.coordinates.row-1].baseDefend?.tanks
  if(trueCell === undefined) {
  baseDef = 0
  
  } else { baseDef = trueCell}

 // const [activeMenu, setActiveMenu] = useState(false);
  // setAction('')
  return (
    <div style={{top:`${posY-200}px`, visibility: visible,left:`${  posX-60}px`,  }} className={style.menu1}> {tank.name}  Боеприпасы:{ loc.state.fraction === undefined  ?      tank.actions.fire.ammunition  : loc.state.fraction === tank.team ? tank.actions.fire.ammunition  : '?'  }  Защита:{tank.actions.defend+baseDef }  Численность:{tank.count}</div>
  );
};
